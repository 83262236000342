import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import cross from '../../assets/cross.svg';
import cross1 from '../../assets/cross-circle.svg';
import tick from '../../assets/tick-green.svg';
import moment from 'moment';
import Web3 from 'web3';
import links from '../../config/constants';
let web3: Web3 | undefined = undefined;
let api_url = process.env.REACT_APP_BACKEND_URL;
let STAKING_CONTRACT_ADDRESS: any = links.STAKING_CONTRACT_ADDRESS;
let STAKING_CONTRACT_ABI: any = links.STAKING_CONTRACT_ABI;


const Checkout = ({ setFlag, userAddress }) => {
    const [total, setTotal] = useState(0);
    const [cartItems, setCartItems] = useState<any>([]);
    const [name, setName] = useState('');
    const [discord, setDiscord] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [email, setEmail] = useState('');
    const [remarks, setRemarks] = useState('');
    const [autoDeductText, setAutoDeductText] = useState('');
    const [userNFTs, setUserNFTs] = useState<any>([]);
    const [showEmail, setShowEmail] = useState(false);
    const [showWalletAddress, setShowWalletAddress] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {
        setShow1(false);
        navigate("/store", { replace: true });
    };
    const handleOpen1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleOpen2 = () => setShow2(true);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleOpen3 = () => setShow3(true);
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleOpen4 = () => setShow4(true);
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleOpen5 = () => setShow5(true);
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleOpen6 = () => setShow6(true);
    const [availableGems, setAvailableGems] = useState(0);
    const [newRewardGems, setNewRewardGems] = useState(0);
    let navigate = useNavigate();

    const getRewardGems = async () => {
        fetch(`${api_url}/claim/record`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
        })
            .then((response) => response.json())
            .then((response) => response.data.record.gems - response.data.record.usedGems)
            .then((response) => setNewRewardGems(response))
    }

    const initializeContract = async (abi, address) => {
        const web3 = new Web3(Web3.givenProvider)
        let contract = await new web3.eth.Contract(abi, address)
        return contract;
    }

    const getUserNFTs = async (address) => {

        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        let stakedAssets = await contract_instance.methods.getStakedTokens(address).call();

        fetch(`${api_url}/staking/filter-nfts`, {
            body: JSON.stringify({ stakedAssets }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
            method: 'POST',
        })
            .then((response) => response.json())
            .then((response) => {
                // let allNFTs = response.data.allNFTs;
                // let nftArray = response.data.unstakedNFTs;
                setUserNFTs(response.data.unstakedNFTs.concat(response.data.stakedNFTs));
                // let itemsProcessed = 0;
                // response.data.stakedNFTs.forEach((nft, index, array) => {
                //     let now = moment(new Date());
                //     let end = moment(nft?.startDate);
                //     let duration = moment.duration(now.diff(end));
                //     let days = duration.asDays();
                //     days = Number(days.toFixed(0));
                //     let gems = 0;
                //     if (days < 30) {
                //         gems = 10 * days;
                //     } else if (days > 30 && days <= 90) {
                //         gems = 10 * 30;
                //         gems += 20 * (days - 30);
                //     } else if (days > 90) {
                //         gems = 10 * 30;
                //         gems += 20 * 60;
                //         gems += 30 * (days - 90);
                //     }
                //     let tempAllNFTs = allNFTs.filter(ele => ele.tokenId == nft.asset);
                //     nftArray.push({
                //         noOfGems: gems + (tempAllNFTs[0]?.noOfGems || 0),
                //         tokenId: nft.asset
                //     })
                //     itemsProcessed++;
                //     if (itemsProcessed == array.length) {
                //         setUserNFTs(nftArray);
                //     }
                // })
            });
    }

    const buy = () => {
        let deductions = JSON.parse(localStorage.getItem('deduction'));
        let perks = cartItems;
        if (!name || !discord || (!walletAddress && !email)) {
            handleOpen();
        } else if (!deductions) {
            let totalSum = total;
            let sortedNFTs = userNFTs.sort((a, b) => {
                return b.noOfGems - a.noOfGems;
            });
            let newDeductionArray = [];

            let claimDeduction = 0;
            if (total < newRewardGems) {
                claimDeduction = total;
            } else {
                claimDeduction = newRewardGems;
                totalSum -= claimDeduction;
                for (let i = 0; i < sortedNFTs?.length && totalSum != 0; i++) {
                    if (sortedNFTs[i].noOfGems >= totalSum) {
                        newDeductionArray.push({
                            asset: sortedNFTs[i].tokenId,
                            amount: totalSum
                        })
                        totalSum = 0;
                    } else {
                        totalSum -= sortedNFTs[i].noOfGems;
                        newDeductionArray.push({
                            asset: sortedNFTs[i].tokenId,
                            amount: sortedNFTs[i].noOfGems
                        })
                    }
                }
                deductions = newDeductionArray;
            }
            fetch(`${api_url}/order`, {
                body: JSON.stringify({
                    name,
                    discord,
                    walletAddress,
                    email,
                    remarks,
                    perks,
                    deductions
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('gemToken')}`
                },
                method: 'POST',
            })
                .then(response => response.json())
                .then(response => {
                    if (response.data.status == 403) {
                        handleOpen4();
                    } else if (response.data.status == 401) {
                        handleOpen5();
                    } else if (response.data.status == 404) {
                        handleOpen3();
                    } else if (response.data.status == 301) {
                        handleOpen6();
                        //You can place next order after 24 hours of your previous order.
                    } else if (response.status == 200) {
                        handleOpen1();
                        localStorage.setItem('cartItems', '[]');
                        localStorage.removeItem('deduction');
                    } else {
                        handleOpen2();
                    }
                })
        } else {
            fetch(`${api_url}/order`, {
                body: JSON.stringify({
                    name,
                    discord,
                    walletAddress,
                    email,
                    remarks,
                    perks,
                    deductions
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('gemToken')}`
                },
                method: 'POST',
            })
                .then(response => response.json())
                .then(response => {
                    if (response.data.status == 403) {
                        handleOpen4();
                    } else if (response.data.status == 401) {
                        handleOpen5();
                    } else if (response.status == 404) {
                        handleOpen3();
                    } else if (response.data.status == 301) {
                        handleOpen6();
                        //You can place next order after 24 hours of your previous orders
                    } else if (response.status == 200) {
                        handleOpen1();
                        localStorage.setItem('cartItems', '[]');
                        localStorage.removeItem('deduction');
                        localStorage.removeItem('claimDeduction');
                    } else {
                        handleOpen2();
                    }
                })
        }
    }

    const getCartItems = () => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        let total = 0;
        for (let i = 0; i < cartItems?.length; i++) {
            total += cartItems[i].price * Number(cartItems[i].quantity);
            if (cartItems[i].type == 'coupon') {
                setShowEmail(true);
            }
            if (cartItems[i].type == 'coin') {
                setShowWalletAddress(true);
            }
        }
        setTotal(total);
        setCartItems(cartItems);
    }

    let items = 0
    for (let i = 0; i < cartItems?.length; i++) {
        items += Number(cartItems[i]?.quantity);
    }

    useEffect(() => {
        let gems = 0
        for (let i = 0; i < userNFTs?.length; i++) {
            gems += userNFTs[i]?.noOfGems;
            setAvailableGems(gems);
        }
    }, [userNFTs])

    useEffect(() => {
        let deductions = JSON.parse(localStorage.getItem('deduction'));
        if (!deductions) {
            setAutoDeductText('Gems Required for this purchase will be deducted from the NFT(s) with Most no. of gems')
        }
        try {
            if (userAddress) {
                getUserNFTs(userAddress);
                setWalletAddress(userAddress);
                getRewardGems();
            } else {
                setUserNFTs([]);
            }
        } catch (e) { }
        getCartItems();
        setFlag(4);
    }, [userAddress])

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onDiscordChange = (event) => {
        setDiscord(event.target.value);
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onWalletAddressChange = (event) => {
        setWalletAddress(event.target.value);
    }

    const onRemarksChange = (event) => {
        setRemarks(event.target.value);
    }

    return (
        <>
            <div className="checkout">
                <div className="container">
                    <div className="row mx-0 justify-content-end mb-4">
                        <div className="col-lg-5">
                            <div className="btn legendryBtn-green">
                                <span className="innerText">You Have {availableGems + newRewardGems} Legendary Gems</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-lg-7 px-0 px-lg-3 mb-240">
                            <div className="fs-36 fw-700 text-white before">Personal Information</div>
                            <form>
                                <div className="row mx-0">
                                    <div className="col-lg-12 mb-30 px-0">
                                        <div className="input-field">
                                            <input type="text" placeholder="Name*" onChange={onNameChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-30 px-0">
                                        <div className="input-field">
                                            <input type="text" placeholder="Discord Name*" onChange={onDiscordChange} />
                                        </div>
                                    </div>
                                    {showWalletAddress && <div className="col-lg-12 mb-30 px-0">
                                        <div className="input-field">
                                            <input type="text" placeholder="Wallet Address*" value={walletAddress} onChange={onWalletAddressChange} />
                                        </div>
                                    </div>}
                                    {showEmail && <div className="col-lg-12 mb-30 px-0">
                                        <div className="input-field">
                                            <input type="text" placeholder="Email Address*" onChange={onEmailChange} />
                                        </div>
                                    </div>}
                                    <div className="col-lg-12 mb-30 px-0">
                                        <div className="input-field">
                                            <textarea placeholder="Other Information" rows={9} onChange={onRemarksChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5 px-0 px-lg-3">
                            <div className="fs-36 fw-700 text-white before">Order Details</div>
                            <div className="orderCard">
                                <div className="inner-box mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Items</div>
                                    <div className="fs-25 fw-700 text-white">{items}</div>
                                </div>
                                <div className="inner-box mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Subtotal</div>
                                    <div className="fs-25 fw-700 textGreen">{total} Gems</div>
                                </div>
                                <div className="inner-box mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Total</div>
                                    <div className="fs-25 fw-700 textGreen">{total} Gems</div>
                                </div>
                                <div className="inneBox-dashed mb-40">
                                    <select>
                                        <option>Use Referral Code</option>
                                    </select>
                                </div>
                                {showWalletAddress && <div className="fs-13 fw-700 text-white mb-40 yellow-color">
                                    Please allow up to 5 business days for order to be processed.
                                </div>}
                                {!autoDeductText && <div className="fs-13 fw-700 text-white mb-40">
                                    Gems will be deducted from NFTs according to the selection you made on the previous page.
                                </div>}
                                {autoDeductText && <div className="fs-13 fw-700 text-white mb-40">
                                    {autoDeductText}
                                </div>}

                                <div className="mb-30">
                                    <a className="btn connectBtn w-100" onClick={() => buy()}>Buy Now</a>
                                </div>
                                <div className="mb-30">
                                    <Link to='/store'>
                                        <a className="btn gradientBtn-outline w-100">Continue Shopping</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={show}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">Missing Required Info!</div>
                        <div><a onClick={() => handleClose()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show1}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose1}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={tick} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">Order Place Successfully!</div>
                        <div><a onClick={() => handleClose1()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show2}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose2}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">An error occured while placing <br />
                            the order!
                        </div>
                        <div><a onClick={() => handleClose2()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show3}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose3}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">One of the requested perk is<br />
                            out of stock!
                        </div>
                        <div><a onClick={() => handleClose3()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show4}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose4}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">Not Enough Gems to complete<br />
                            the purchase!
                        </div>
                        <div><a onClick={() => handleClose4()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show5}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose5}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">Quantity per perk should be 1!
                        </div>
                        <div><a onClick={() => handleClose5()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show6}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose6}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">You can place next order after 24 hours <br /> of your previous orders!
                        </div>
                        <div><a onClick={() => handleClose6()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default Checkout