import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer className="">
        <div className="container">
            <div className="d-md-flex justify-content-between topBorder">
              <div className="rightReserved-text mb-4 mb-md-0">© 2022 House of Legends. All rights reserved.</div>
              <div>
                <ul className='privacy-links'>
                  <li>
                    <Link to='/privacy-policy' className="pointer">Privacy Policy</Link>
                    {/* <a href='https://www.houseoflegends.art/privacy-policy' target="_blank" rel="noopener noreferrer" className="pointer">Privacy Policy</a> */}
                  </li>
                  <li className='mx-2'>|</li>
                  <li>
                    <Link to="/terms" className="pointer">Terms & Conditions</Link>
                    {/* <a href='https://www.houseoflegends.art/terms-and-conditions' target="_blank" rel="noopener noreferrer" className="pointer">Terms & Conditions</a> */}
                  </li>
                </ul>
              </div>
              </div>
          </div>
      </footer>
    </div>
  )
}

export default Footer