import React, { useState } from 'react'
import minus from '../../assets/minus.svg';
import plus from '../../assets/plus.svg';

export const Question: React.FC<any> = ({ question }) => {
    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
        setToggle(!toggle)
    }
    return (
        <>
            <div className="question">
                <div className="faq-inner-card">
                    <summary>
                        <span className="pr-4 pr-md-0">{question.title}</span>
                        <span onClick={handleToggle} className="faq-open-icon">
                            {!toggle ? <img src={plus} /> : <img src={minus} />}
                        </span>
                    </summary>
                    {toggle && <span className="faq-card-spoiler">{question.question}</span>}
                </div>
            </div>
        </>
    )
}