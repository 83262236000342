import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from "react";
import LegendaryStore from './components/LegendaryStore/LegendaryStore';
import Home from './components/Home/Home';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import TempNFT from './components/TempNFT/TempNFT';
import Stake from './components/Stake/Stake';
import Cart from './components/Cart/Cart';
import Checkout from './components/Checkout/Checkout';
import NftCheckout from './components/Checkout/NftCheckout';
import Detail from './components/Detail/Detail';
import Faq from './components/Faq/Faq';
import Landing from './components/Landing/Landing';
import OrderHistory from './components/orderHistory/OrderHistory';
import PrivacyPolicy from './components/Privacy-policy/PrivacyPolicy';
import TermCondions from './components/Terms-conditions/TermCondions';

function App() {
  const [input, setInput] = useState();
  const [inputGems, setInputGems] = useState();
  const [flag, setFlag] = useState(0);
  const [userAddress, setUserAddress] = useState('');
  const [rewardGems, setRewardGems] = useState(0);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  return (
    <div>       
      <Router>
        <Header 
          setInput={setInput} 
          setInputGems = {setInputGems} 
          flag={flag} 
          setUserAddress = {setUserAddress} 
          address2={userAddress} 
          setRewardGems={setRewardGems}
          cartItemsCount={cartItemsCount}
          setCartItemsCount={setCartItemsCount}
        />
        <Routes>
          <Route path='/' element={<Landing setFlag={setFlag} setUserAddress={setUserAddress}/>}/>
          <Route path='/gems' element={<Home input={input} setFlag={setFlag}/>} />
          <Route path='/my-vault' element={<Stake setFlag={setFlag} userAddress={userAddress} rewardGems={rewardGems} setUserAddress={setUserAddress} setCartItemsCount={setCartItemsCount}/>}/>
          <Route path='/store' element={<LegendaryStore setFlag={setFlag} userAddress={userAddress} inputGems={inputGems} setCartItemsCount={setCartItemsCount}/>} />
          <Route path='/cart' element={<Cart setFlag={setFlag} userAddress={userAddress} setCartItemsCount={setCartItemsCount}/>} />
          <Route path='/checkout' element={<Checkout setFlag={setFlag} userAddress={userAddress}/>} />
          <Route path='/nft-checkout' element={<NftCheckout setFlag={setFlag} userAddress={userAddress}/>} />
          <Route path='/detail' element={<Detail setFlag={setFlag}/>} />
          <Route path='/faq' element={<Faq setFlag={setFlag}/>} />
          <Route path='/getAllNFTs' element={<TempNFT />}/>
          <Route path='/order-history' element={<OrderHistory/>}/>
          <Route path='/terms' element={<TermCondions setFlag={setFlag} />}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy setFlag={setFlag} />}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
