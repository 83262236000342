import { useState, useEffect } from "react";
import productImg from '../../assets/product-detail.png';
import cartIcon from '../../assets/cart-icon.svg';
import cardImg from "../../assets/card-img.png";
import upChevron from '../../assets/upChevron.svg';



const Detail = ({ setFlag }) => {
    useEffect(() => {
        setFlag(5)
    })
    return (
        <>
            <div className="productDetail">
                <div className="container">
                    <div className="row mx-0 mb-40">
                        <div className="col-lg-7 pl-lg-0 px-xs-0 mb-4 mb-md-0">
                            <div className="productCard">
                                <div className="productImg">
                                    <img src={productImg} alt="" />
                                    <div className="detailBadge">Free Mint</div>
                                    <span className="d-block d-lg-none chevron"><img src={upChevron} alt="" /></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pr-lg-0 px-xs-0">
                           <div className="productCard">
                                <div className="cardHeading">Duis in dictum suspendisse sed sagittis
                                    malesuada odio. </div>
                                <div className="price">
                                    <div>
                                        <div className="fs-13 textWhite80">Price</div>
                                        <div className="fs-25 fw-700 text-white">1400 Gems</div>
                                    </div>
                                    <div className="offSale">
                                        <div className="innerText">50% off on This Item</div>
                                    </div>
                                </div>
                                <div className="alreadySold mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Already Sold</div>
                                    <div className="fs-25 fw-700 text-white">60</div>
                                </div>
                                <div className="alreadySold mb-40">
                                    <div className="fs-16 fw-700 textWhite70">Left Only</div>
                                    <div className="fs-25 fw-700 text-white">12</div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-50">
                                        <button className="btn-with-icon">
                                            <span className="d-none d-lg-block"><img src={cartIcon} alt="" /></span>
                                            Add To Cart
                                        </button>
                                    </div>
                                    <div className="w-50 pl-20"><button className="connectBtn">Buy</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detailTabs">
                        <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="fs-25 fw-700 text-white mb-30">Nulla nulla urna gravida leo sagittis.</div>

                                <div className="fs-16 fw-400 textWhite70 mb-20">
                                    Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue.
                                    Id fermentum diam at elit faucibus nullam sollicitudin vel. Nisi bibendum nulla sed turpis eget ultricies convallis commodo. Id nunc dignissim odio ultricies eget. Nibh pharetra pharetra, nunc, leo. Diam vel neque morbi metus, enim porta. Natoque massa est euismod velit id semper tristique. Nisl adipiscing diam ac sagittis mi pharetra, malesuada. Diam mauris ullamcorper proin fringilla diam ultrices scelerisque duis tristique. Maecenas velit non rutrum consectetur. Tristique diam tristique egestas cras.
                                </div>

                                <div className="fs-16 fw-400 textWhite70">
                                    Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue.
                                </div>

                            </div>
                            <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="fs-25 fw-700 text-white mb-30">Nulla nulla urna gravida leo sagittis.</div>

                                <div className="fs-16 fw-400 textWhite70 mb-20">
                                    Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue.
                                    Id fermentum diam at elit faucibus nullam sollicitudin vel. Nisi bibendum nulla sed turpis eget ultricies convallis commodo. Id nunc dignissim odio ultricies eget. Nibh pharetra pharetra, nunc, leo. Diam vel neque morbi metus, enim porta. Natoque massa est euismod velit id semper tristique. Nisl adipiscing diam ac sagittis mi pharetra, malesuada. Diam mauris ullamcorper proin fringilla diam ultrices scelerisque duis tristique. Maecenas velit non rutrum consectetur. Tristique diam tristique egestas cras.
                                </div>

                                <div className="fs-16 fw-400 textWhite70">
                                    Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MoreProduct">
                        <div className="fs-36 fw-700 text-white mb-5">More Products</div>
                        <div className="card-section">
                        <div className="gemCard">
                            <div className="gem-img">
                                <img src={cardImg} alt="" />
                            </div>
                            <div className="legendBadge">Free Mint</div>
                            <div className="gemBody">
                                <div className="d-flex  justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="price">Price</div>
                                        <div className="fs-25 fw-700 text-white">1400 Gems</div>
                                    </div>
                                    <div className=""><button className='gradientBtn-outline'>Buy</button></div>
                                </div>
                                <div className="footer-card">
                                    <div>Already Sold: <span className='fs-25'>60</span></div>
                                    <div>Left Only: <span className='fs-25'>12</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="gemCard">
                            <div className="gem-img">
                                <img src={cardImg} alt="" />
                            </div>
                            <div className="legendBadge">Free Mint</div>
                            <div className="gemBody">
                                <div className="d-flex  justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="price">Price</div>
                                        <div className="fs-25 fw-700 text-white">1400 Gems</div>
                                    </div>
                                    <div className=""><button className='gradientBtn-outline'>Buy</button></div>
                                </div>
                                <div className="footer-card">
                                    <div>Already Sold: <span className='fs-25'>60</span></div>
                                    <div>Left Only: <span className='fs-25'>12</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="gemCard">
                            <div className="gem-img">
                                <img src={cardImg} alt="" />
                            </div>
                            <div className="legendBadge">Free Mint</div>
                            <div className="gemBody">
                                <div className="d-flex  justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="price">Price</div>
                                        <div className="fs-25 fw-700 text-white">1400 Gems</div>
                                    </div>
                                    <div className=""><button className='gradientBtn-outline'>Buy</button></div>
                                </div>
                                <div className="footer-card">
                                    <div>Already Sold: <span className='fs-25'>60</span></div>
                                    <div>Left Only: <span className='fs-25'>12</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="gemCard">
                            <div className="gem-img">
                                <img src={cardImg} alt="" />
                            </div>
                            <div className="legendBadge">Free Mint</div>
                            <div className="gemBody">
                                <div className="d-flex  justify-content-between">
                                    <div className="d-flex flex-column">
                                        <div className="price">Price</div>
                                        <div className="fs-25 fw-700 text-white">1400 Gems</div>
                                    </div>
                                    <div className=""><button className='gradientBtn-outline'>Buy</button></div>
                                </div>
                                <div className="footer-card">
                                    <div>Already Sold: <span className='fs-25'>60</span></div>
                                    <div>Left Only: <span className='fs-25'>12</span></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Detail