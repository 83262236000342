
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import  App  from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
// test comments added
ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider 
      appId="olsLXRBsPPaeKp861pGEXZs0Fc9Njg7M0tzEW2Gk" 
      serverUrl="https://d1aqvg2oym8a.usemoralis.com:2053/server">
      <App />
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


