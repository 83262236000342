import React, { useEffect } from 'react';
import './Privacy.css';

const PrivacyPolicy = ({ setFlag }) => {

  useEffect(() => {
    setFlag(12)
  }, [])

  return (
    <>
      <div className='privacyPolicy'>
        <div className="container">
          <div className="row justify-content-center mx-0">
            <div className="col-lg-9">


              <div className="fs-70 fw-700 text-white mb-4">Privacy Policy</div>

              <div className="fs-16 fw-400 text-white mb-3">
                This Privacy Policy is valid from 19 December 2021 and applies to META BROTHERS LLC’s website located at
                <a href="https://www.houseoflegends.art" className='text-white' target="_blank" rel="noreferrer noopener"><u>https://www.houseoflegends.art/. </u></a>
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                References to “Company”, “we” and “our” throughout this Privacy Policy collectively refer to the aforementioned legal  entity, and references to “User”, “you” and “your” this Privacy Policy collectively refer to any User using the Website.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Terms and definitions used in this Privacy Policy
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                GDPR - EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data  and on the free movement of such data, and repealing Directive 95/46/EC.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Personal data - Any information relating to an identified or identifiable natural person; an identifiable natural person  is one who can be identified, directly or indirectly, in particular by reference to an  identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical,  physiological, genetic, mental, economic, cultural or social identity of that natural person.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Consent - Freely given, specific, informed and unambiguous indication of the data subject's wishes by which data  subject, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data  relating to the data subject.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Data subject - An identified or identifiable natural person whose personal data is processed.
              </div>
              <div className="fs-16 fw-400 text-white mb-4">
                Processing - Any operation or set of operations which is performed on personal data or on sets of personal data,  whether or not by automated means, such as collection, structuring, storage, adaptation or alteration, retrieval,  recording, organization, consultation, use, disclosure by transmission, dissemination or otherwise making available,  alignment or combination, restriction, erasure or destruction.
              </div>
              <div className="fs-16 fw-500 text-white text-center mb-3">
                1.What Personal Data we may process
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you send us a notification through the “Contact” section of our website - We may process your first name, last  name, name of the company you represent (if any), e-mail address, phone number, and information provided by  you in the text section.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you visit and/or use our website - Certain information may be collected automatically, such as the Internet  protocol (IP) address of your computer, Ethereum address, name of the domain and host from which you access  the Internet, the browser software and operating system of your computer, wallet type, and the Internet address of  the website from which you linked directly to our website.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you contact us via phone or we have audio or video call communication with you - We may process your voice,  video and the information provided during the entire phone call.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you contact us via our email - We may process your email address and any data that you submit while  communicating.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you are a user of any of our services/products - We may also collect data for the purpose of agreement  performance, pricing, your age, full name, address, email, and other information related to your agreement history.
              </div>
              <div className="fs-16 fw-400 text-white mb-4">
                For specific activities, services or software, we may collect other types of information and use such information for  different purposes than described in this Privacy Policy. We inform you about this when you apply for the specific  service, engage in specific engagement with us or use the specific software.
              </div>
              <div className="fs-16 fw-500 text-white text-center mb-3">
                2.Source of Personal Data we process
              </div>
              <div className="fs-16 fw-500 text-white mb-3">
                Generally, the personal data we process is obtained directly from you. However, there are situations in which we  may also be in possession of your personal data through other legitimate means, such as executing an agreement  to which you are not a party:
              </div>

              <ul className='mb-4'>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">If you are designated as an authorized person or business contact for our partners;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">If we provide you with our services;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">If you are our employee;;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">If you allow recruitment platforms to share your CV or resume with us;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white">Depending on your social network settings, we may receive information from your social network. For example,  when you follow us via social media platforms, such as LinkedIn and Facebook, we can receive personal data  from these social media platforms related to your profile.</div>
                </li>
              </ul>

              <div className="fs-16 fw-500 text-white text-center mb-3">
                3.For how long we keep your Personal Data
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                We keep your personal data for the period necessary for the specific purpose listed below or in so far as such is  necessary for compliance with statutory obligations and for solving any disputes. We will delete your personal  information as soon as it is no longer needed for the specific purpose.
              </div>

              <div className="fs-16 fw-400 text-white mb-4">
                We are required to retain business and commercial documentation and other tax-relevant documents in order to  fulfill our commercial and tax law archiving obligations. Retention periods are determined by applicable laws.
              </div>

              <div className="fs-16 fw-500 text-white text-center mb-3">
                4. Purposes of the Processing
              </div>

              <div className="fs-16 fw-400 text-white">
                Your personal data are processed by us for legitimate purposes, according to the legal regulations in force, as follows:
              </div>

              <ul className='mb-4'>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    To process, confirm and fulfil your request, including confirming payment, and updating you on the status of  your request/order/agreement;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    For User support and general assistance;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    To manage communications systems and IT systems, conducting audit reports, managing database security  and all IT systems;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    To draft tax and financial documents and collect payment amounts;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    To issue product/service invoices, return invoices and payment orders;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    To read and answer your messages and to resolve complaints received from you through the “Contact” section  of our website, social media, email or via any other mean of communication;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Conducting recruitment/selection activities for filling vacancies and managing recruitment/contest files during  the various stages of the procedure;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Streamlining the services made available to the clients and constantly improving the quality of the services  offered, in particular through the recording of calls or web services and products by inviting you to complete our  surveys;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Representation of Company in courts and before public authorities;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Performing statistical research into general trends regarding the use of this website and the  behavior of the website’s visitors to improve our website;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    For record keeping and to comply with statutory obligations, we collect, store and use your data for internal business purposes, such as record keeping and to comply with our legal and fiscal obligations.
                  </div>
                </li>
              </ul>

              <div className="fs-16 fw-500 text-white text-center mb-3">5. Legal Basis of Processing</div>

              <div className="fs-16 fw-400 text-white">
                We collect, use and store your personal data to comply with the legal obligations we are subject to, if necessary for  our legitimate interests or the interests of a third party, for the execution of an agreement, or on the basis of your  consent.
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                When we process your personal data for our legitimate interests or the interests of a third party, we have balanced  these interests against your legitimate interests. Where necessary we have taken appropriate measures to limit  implications and prevent unwarranted harm to you. Our legitimate interests may, for example, include security and  safety purposes, to improve our website, or to provide better services and offers to you.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Where we process your personal data for a purpose other than that for which we collected it initially (and we rely on  a legal basis other than consent or complying with legal obligations for this new purpose), we will ascertain whether  processing for this new purpose is compatible with the purpose for which the personal data were initially collected.

                The specific legal basis of processing your personal data are:
              </div>

              <ul className="mb-4">
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Making steps at your request before concluding an agreement (Article 6 (1) (b), of the GDPR);
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    The fulfillment of legal obligations by us (Article 6 (1) (c) of GDPR);
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    The legitimate interest of us (Article 6 (1) (f) of the GDPR), such as: the organization of the entire Company  activity for the purpose of carrying out the activity; Scheduling of IT applications; solving complaints and  requests received from clients or other interested persons; initiating and conducting litigation by courts of law  and (possibly) other public authorities;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Your consent to processing, when expressly granted, freely and unconditionally, in specific situations such as,  for example, marketing processing (Article 6 (1) (a) of the GDPR).
                  </div>
                </li>
              </ul>

              <div className="fs-16 fw-500 text-white text-center mb-4">
                6. Distribution of Personal Data
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                Generally, we do not sell or share your personal data with anyone outside Company. However, we may disclose or share data with the following categories of recipients for the following purposes:
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Support services - We may use third parties for support services to our website, such as social network providers, marketing agencies and IT suppliers. All such third parties may require to protect your personal data and only process it as per our instructions.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Third-party websites - Our website may contain links to third-party websites; if you follow these links, you exit our website. While these third-party websites are selected with care, we cannot accept liability for the use of your personal data by these third parties.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Public authorities - If we are required to by law, court order or any other order of public authorities, we may share your personal data with public authorities or governmental organizations.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Sharing with affiliate companies and ownership change of Company - We may share your personal data with our affiliate company if this is necessary for the purposes listed above. If ownership of Company changes as a result of a merger, acquisition, transfer, sale of assets, reorganization or bankruptcy your personal data may be transferred to the successor entity.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Researches - For research purposes we may use third party software such as Plausible Analytics.
              </div>

              <div className="fs-16 fw-400 text-white text-center mb-4">
                7. Personal Data transfers to third countries
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                Company may transfer your personal data to countries other than your country of residence, including but not limited to countries outside the European Economic Area. International transferring occurs in the course of providing your services. Partners or service providers have operations in countries across the world. The laws of these countries may not afford the same level of protection to your personal data.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Company ensures that proper and required protection is in place to comply with the requirements for the international transfer of personal data under applicable privacy laws. For transfers of personal data outside the European Economic Area, Company may use European Commission approved Standard Contractual Clauses as safeguards.
              </div>

              <div className="fs-16 fw-400 text-white text-center mb-4">
                8. Data security
              </div>

              <div className="fs-16 fw-400 text-white mb-3">
                Company ensures that your personal data are properly secured by appropriate technical and organizational measures, so that they are protected against unauthorized or unlawful use, alteration, unauthorized access or disclosure, accidental or wrongful destruction, and loss. However, no security system is impenetrable and Company cannot guarantee the security of the systems. In the event that any information is compromised as a result of a breach of security, Company will take steps to investigate the situation and, where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.
              </div>

              <div className="fs-16 fw-400 text-white text-center mb-4">9. Your rights </div>

              <div className="fs-16 fw-400 text-white mb-3">You may contact us (please see par. 11 “How to contact us” below) to exercise any of the rights you are granted under applicable data protection laws, which includes the following:</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to access - You may ask us whether or not we process any of your personal data and, if so, receive access to that personal data in the form of a copy. When complying with an access request, we will also provide you with additional information necessary for you to exercise the essence of this right.</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to rectification - You may have your personal data rectified in case of inaccuracy or incompleteness. Upon request, we will correct inaccurate personal data about you and, taking into account the purposes of the processing, complete incomplete personal data, which may include the provision of a supplementary statement.</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to restriction of Processing - You may obtain a restriction of the processing of your personal data, which means that we suspend the processing of your data for a certain period of time. Circumstances which may give rise to this right include situations where the accuracy of your personal data was contested but some time is needed for us to verify their (in)accuracy. This right does not prevent us from continuing storing your personal data. We will inform you before the restriction is lifted.</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to erasure - You may have your personal data erased, which means the deletion of your data by us and, where possible, by any other controller to whom your data has previously been made public by us. Erasure of your personal data only finds a place in certain cases, prescribed by law and listed under article 17 of the GDPR. Due to the way we maintain certain services, it may take some time before backup copies are erased.</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to data portability - You may request us to provide you with your personal data in a structured, commonly used and machine-readable format and to have such data transmitted directly to another controller, where technically feasible. Upon request and where this is technically feasible, we will transmit your personal data directly to the other controller.</div>
              <div className="fs-16 fw-400 text-white mb-3">Right to object - You may object to the processing of your personal data, which means you may request us to no longer process your personal data. This only applies in case the ‘legitimate interests’ ground constitutes the legal basis for processing (par. 6 “Legal Basis of Processing” above).</div>
              <div className="fs-16 fw-400 text-white mb-3">Under conditions, we are entitled to deny or restrict your rights as described above. In any case, we will carefully assess whether such an exemption applies and inform you accordingly.</div>
              <div className="fs-16 fw-400 text-white mb-3">We may, for example, deny your request for access when necessary to protect the rights and freedoms of other individuals or refuse to delete your personal data in case the processing of such data is necessary for compliance with legal obligations. The right to data portability, for example, does not apply in case the personal data was not provided by you or if we process the data not on the basis of your consent or for the performance of a contract.</div>

              <div className="fs-16 fw-500 text-white text-center mb-4">10. How to contact us</div>


              <div className="fs-16 fw-400 text-white mb-3">
                If you have any questions or concerns, or need to exercise your rights listed above, you can contact us via email, phone or regular mail directed to:
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Address:  12 Fuller Dr West Hartford, CT, 06117 USA
                Email: legends@meta-brothers.com
                <a href="#" className="text-white"><u></u></a>
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you have unresolved concerns, you also have the right to contact the data protection authorities.
              </div>

              <div className="fs-16 fw-500 text-white text-center mb-4">11. Amendment to Privacy Policy</div>

              <div className="fs-16 fw-400 text-white">
                This Privacy policy was most recently amended on 18 December 2021 and replaces earlier versions. We may update and/or change the terms of this Privacy Policy, and we may post any substantive changes on our website and provide to you by email prior to these changes taking effect, so that our users and/or visitors are aware of the type of information we collect, how it will be used, and under what circumstances, if any, we may disclose such information.
              </div>

              <div className="fs-16 fw-500 text-white text-center mb-4">12. ​Consent</div>

              <div className="fs-16 fw-400 text-white mb-4">
                When negotiating or/and entering into an agreement with Company, or visiting our website, or sending us notifications, or calling us, you implicitly acknowledge that you have read, and agree with this Privacy Policy and you hereby consent to our collection, use, management, retention, and disclosure of your information as described herein.
              </div>





            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy

function flag(flag: any) {
  throw new Error('Function not implemented.');
}
