import Web3 from 'web3';
import loading from '../../assets/loading.jpg';
const Card = (props) => {
  
  const initializeContract = async(abi, address) => {
    const web3 = new Web3(Web3.givenProvider)
    let contract = await new web3.eth.Contract(abi, address)
    return contract;
  }


  let asset = props.info
  return (
    <div>
          <div className="gemCard">
            <div className="gem-img">
              <img src={"https://legendary-vault.s3.us-west-1.amazonaws.com/images/nfts/"+ asset?.tokenId +".png"} alt="" />
              {/* <img src={loading} alt="" /> */}
              {/* <img src={'http:localhost:5000/images/' + asset.tokenId + '.png'} alt="" /> */}
            </div>
            <div className="legendBadge">Legend #{asset?.tokenId}</div>
            <div className="gemBody">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <div className="price">$GEMS Earned</div>
                  <div className="fs-25 fw-700 text-white">{asset?.noOfGems} $GEMS</div>
                </div>
                <div className=""><a href={"https://opensea.io/assets/0x8c714199d2ea08cc1f1f39a60f5cd02ad260a1e3/" + asset.tokenId} target="_blank" rel="noopener noreferrer" className='gradientBtn'>View On Opensea</a></div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Card