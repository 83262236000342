import { useState, useEffect } from "react";
import 'react-input-range/lib/css/index.css';
import ReactPaginate from 'react-paginate';
import Select from 'react-dropdown-select';
import Card from "../Card/Card"
import dropdownIcon from "../../assets/Arrow (Stroke).svg";
import arrowRight from "../../assets/arrow-right.svg";
import arrowGradient from "../../assets/arrowRight-gradient.svg";
import arrowLeft from "../../assets/arrowLeft.svg";
import InputRange from "react-input-range";
import {
  ACCESSORIES, 
  FOURTH_CLUB, 
  THIRD_CLUB,
  SECOND_CLUB,
  CLUB,
  EAR,
  SPECIAL_CLUB_ITEM,
  SPECIAL_VIBES,
  SPECIAL_EDITION,
  SPECIAL_LEGEND_ITEM,
  LEGEND,
  VIBES,
  BACKGROUND,
  CLOTHES,
  HAIR,
  TATTOOS,
} from "../../config/constants";
let api_url = process.env.REACT_APP_BACKEND_URL;


const Home = ({ input, setFlag }) => {
  const [result, setResult] = useState<any>([]);
  const [range, setRange] = useState({ min: 0, max: 100 });
  const [maxGems, setMaxGems] = useState(0);
  const [sortByLegendNo, setSortByLegendNo] = useState(0);
  const [sortByGems, setSortByGems] = useState(0);
  const options = [
    { value: 'Gems', label: 'Gems' },
    { value: 'Legend #', label: 'Legend #' },
  ]

  //Arrays to send to BE
  const [accessories, setAccessories] = useState([]);
  const [background, setBackground] = useState([]);
  const [club, setClub] = useState([]);
  const [ear, setEar] = useState([]);
  const [fourthClub, setFourthClub] = useState([]);
  const [legend, setLegend] = useState([]);
  const [secondClub, setSecondClub] = useState([]);
  const [specialClubItem, setSpecialClubItem] = useState([]);
  const [specialEdition, setSpecialEdition] = useState([]);
  const [specialLegendItem, setSpecialLegendItem] = useState([]);
  const [specialVibes, setSpecialVibes] = useState([]);
  const [thirdClub, setThirdClub] = useState([]);
  const [vibes, setVibes] = useState([]);
  const [clothes, setClothes] = useState([]);
  const [hair, setHair] = useState([]);
  const [tattoos, setTattoos] = useState([]);


  //Arrays to render Checkboxes
  let accessoriesArray = [];
  let backgroundArray = [];
  let clubArray = [];
  let earArray = [];
  let fourthClubArray = [];
  let legendArray = [];
  let secondClubArray = [];
  let specialClubItemArray = [];
  let specialEditionArray = [];
  let specialLegendItemArray = [];
  let specialVibesArray = [];
  let thirdClubArray = [];
  let vibesArray = [];
  let clothesArray = [];
  let hairArray = [];
  let tattoosArray = [];
  
  for(let i = 0;i < ACCESSORIES.length;i++) {
      accessoriesArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
          <div className="d-flex align-items-center">
          <input type="checkbox" id="vehicle1" onChange={(e) => onAccessoriesChange(e, i)}/>
              <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {ACCESSORIES[i].label}</label>
          </div>
          <div className="fs-14 fw-500 text-white">
            {ACCESSORIES[i].count}
          </div>
      </div>
    )
  }

  for(let i = 0;i < FOURTH_CLUB.length;i++) {
    fourthClubArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onFourthClubChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {FOURTH_CLUB[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {FOURTH_CLUB[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < THIRD_CLUB.length;i++) {
    thirdClubArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onThirdClubChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {THIRD_CLUB[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {THIRD_CLUB[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < SECOND_CLUB.length;i++) {
    secondClubArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onSecondClubChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {SECOND_CLUB[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {SECOND_CLUB[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < CLUB.length;i++) {
    clubArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onClubChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {CLUB[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {CLUB[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < EAR.length;i++) {
    earArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onEarChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {EAR[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {EAR[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < SPECIAL_VIBES.length;i++) {
    specialVibesArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onSpecialVibesChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {SPECIAL_VIBES[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {SPECIAL_VIBES[i].count}
        </div>
    </div>
  )
  }

  for(let i = 0;i < SPECIAL_CLUB_ITEM.length;i++) {
    specialClubItemArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onSpecialClubItemChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {SPECIAL_CLUB_ITEM[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {SPECIAL_CLUB_ITEM[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < SPECIAL_EDITION.length;i++) {
    specialEditionArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onSpecialEditionChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {SPECIAL_EDITION[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {SPECIAL_EDITION[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < SPECIAL_LEGEND_ITEM.length;i++) {
    specialLegendItemArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onSpecialLegendItemChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {SPECIAL_LEGEND_ITEM[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {SPECIAL_LEGEND_ITEM[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < LEGEND.length;i++) {
    legendArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onLegendChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {LEGEND[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {LEGEND[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < VIBES.length;i++) {
    vibesArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onVibesChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {VIBES[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {VIBES[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < BACKGROUND.length;i++) {
    backgroundArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onBackgroundChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {BACKGROUND[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {BACKGROUND[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < CLOTHES.length;i++) {
    clothesArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onClothesChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {CLOTHES[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {CLOTHES[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < HAIR.length;i++) {
    hairArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onHairChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {HAIR[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {HAIR[i].count}
        </div>
    </div>
    )
  }

  for(let i = 0;i < TATTOOS.length;i++) {
    tattoosArray.push(<div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center">
        <input type="checkbox" id="vehicle1" onChange={(e) => onTattoosChange(e, i)}/>
            <label htmlFor="vehicle1" className="text-white fs-14 ml-2 mb-0"> {TATTOOS[i].label}</label>
        </div>
        <div className="fs-14 fw-500 text-white">
          {TATTOOS[i].count}
        </div>
    </div>
    )
  }

  const onAccessoriesChange = (event, index) => {
    let temp = accessories;
    if(event.target.checked){
      temp.push(ACCESSORIES[index].label);
    }else{
      let i = temp.indexOf(ACCESSORIES[index].label);
      temp.splice(i,1);
    }
    setAccessories([...temp]);
    getNFTs(1,30);
  }

  const onBackgroundChange = (event, index) => {
    let temp = background;
    if(event.target.checked){
      temp.push(BACKGROUND[index].label);
    }else{
      let i = temp.indexOf(BACKGROUND[index].label);
      temp.splice(i,1);
    }
    setBackground([...temp]);
    getNFTs(1,30);

  }

  const onClubChange = (event, index) => {
    let temp = club;
    if(event.target.checked){
      temp.push(CLUB[index].label);
    }else{
      let i = temp.indexOf(CLUB[index].label);
      temp.splice(i,1);
    }
    setClub([...temp]);
    getNFTs(1,30);
  }

  const onEarChange = (event, index) => {
    let temp = ear;
    if(event.target.checked){
      temp.push(EAR[index].label);
    }else{
      let i = temp.indexOf(EAR[index].label);
      temp.splice(i,1);
    }
    setEar([...temp]);
    getNFTs(1,30);
  }

  const onFourthClubChange = (event, index) => {
    let temp = fourthClub;
    if(event.target.checked){
      temp.push(FOURTH_CLUB[index].label);
    }else{
      let i = temp.indexOf(FOURTH_CLUB[index].label);
      temp.splice(i,1);
    }
    setFourthClub([...temp]);
    getNFTs(1,30);
  }

  const onLegendChange = (event, index) => {
    let temp = legend;
    if(event.target.checked){
      temp.push(LEGEND[index].label);
    }else{
      let i = temp.indexOf(LEGEND[index].label);
      temp.splice(i,1);
    }
    setLegend([...temp]);
    getNFTs(1,30);
  }

  const onSecondClubChange = (event, index) => {
    let temp = secondClub;
    if(event.target.checked){
      temp.push(SECOND_CLUB[index].label);
    }else{
      let i = temp.indexOf(SECOND_CLUB[index].label);
      temp.splice(i,1);
    }
    setSecondClub([...temp]);
    getNFTs(1,30);
  }

  const onSpecialClubItemChange = (event, index) => {
    let temp = specialClubItem;
    if(event.target.checked){
      temp.push(SPECIAL_CLUB_ITEM[index].label);
    }else{
      let i = temp.indexOf(SPECIAL_CLUB_ITEM[index].label);
      temp.splice(i,1);
    }
    setSpecialClubItem([...temp]);
    getNFTs(1,30);
  }

  const onSpecialEditionChange = (event, index) => {
    let temp = specialEdition;
    if(event.target.checked){
      temp.push(SPECIAL_EDITION[index].label);
    }else{
      let i = temp.indexOf(SPECIAL_EDITION[index].label);
      temp.splice(i,1);
    }
    setSpecialEdition([...temp]);
    getNFTs(1,30);
  }

  const onSpecialLegendItemChange = (event, index) => {
    let temp = specialLegendItem;
    if(event.target.checked){
      temp.push(SPECIAL_LEGEND_ITEM[index].label);
    }else{
      let i = temp.indexOf(SPECIAL_LEGEND_ITEM[index].label);
      temp.splice(i,1);
    }
    setSpecialLegendItem([...temp]);
    getNFTs(1,30);
  }

  const onSpecialVibesChange = (event, index) => {
    let temp = specialVibes;
    if(event.target.checked){
      temp.push(SPECIAL_VIBES[index].label);
    }else{
      let i = temp.indexOf(SPECIAL_VIBES[index].label);
      temp.splice(i,1);
    }
    setSpecialVibes([...temp]);
    getNFTs(1,30);
  }

  const onThirdClubChange = (event, index) => {
    let temp = thirdClub;
    if(event.target.checked){
      temp.push(THIRD_CLUB[index].label);
    }else{
      let i = temp.indexOf(THIRD_CLUB[index].label);
      temp.splice(i,1);
    }
    setThirdClub([...temp]);
    getNFTs(1,30);
  }

  const onVibesChange = (event, index) => {
    let temp = vibes;
    if(event.target.checked){
      temp.push(VIBES[index].label);
    }else{
      let i = temp.indexOf(VIBES[index].label);
      temp.splice(i,1);
    }
    setVibes([...temp]);
    getNFTs(1,30);
  }

  const onTattoosChange = (event, index) => {
    let temp = tattoos;
    if(event.target.checked){
      temp.push(TATTOOS[index].label);
    }else{
      let i = temp.indexOf(TATTOOS[index].label);
      temp.splice(i,1);
    }
    setTattoos([...temp]);
    getNFTs(1, 30);
  }

  const onClothesChange = (event, index) => {
    let temp = clothes;
    if(event.target.checked){
      temp.push(CLOTHES[index].label);
    }else{
      let i = temp.indexOf(CLOTHES[index].label);
      temp.splice(i,1);
    }
    setClothes([...temp]);
    getNFTs(1,30);
  }

  const onHairChange = (event, index) => {
    let temp = hair;
    if(event.target.checked){
      temp.push(HAIR[index].label);
    }else{
      let i = temp.indexOf(HAIR[index].label);
      temp.splice(i,1);
    }
    setHair([...temp]);
    getNFTs(1,30);
  }


  let cards = [];
  for (let i = 0; i < result?.length; i++) {
    cards.push(<Card key={i} info={result[i]} />)
  }

  const onSortByChange = (event) => {
    console.log(event);
    if (event[0].value == 'Legend #') {
      setSortByLegendNo(1);
      setSortByGems(0);
    } else if (event[0].value == 'Gems') {
      setSortByGems(1);
      setSortByLegendNo(0);
    }
  }

  const getNFTs = (page, limit) => {
    fetch(`${api_url}/nft?page=${page}&limit=${limit}&legendNo=${sortByLegendNo}&gems=${sortByGems}&accessories=${accessories}&background=${background}&club=${club}&ear=${ear}&fourthClub=${fourthClub}&legend=${legend}&secondClub=${secondClub}&specialClubItem=${specialClubItem}&specialEdition=${specialEdition}&specialLegendItem=${specialLegendItem}&specialVibes=${specialVibes}&thirdClub=${thirdClub}&vibes=${vibes}&clothes=${clothes}&hair=${hair}&tattoos=${tattoos}`)
    .then(response => response.json())
    .then(response => {
      setResult(response.data.result?.docs);
      setRange({ min: 0, max: response.data.maxGems });
      setMaxGems(response.data.maxGems);
    })
    .then(response => {
      cards = [];
      for (let i = 0; i < result?.length; i++) {
        cards.push(<Card key={i} info={result[i]} />)
      }
    })
  }

  const getSearchedNFTs = (input) => {
    fetch(`${api_url}/nft/search?tokenId=${input}`)
      .then(response => response.json())
      .then(response => setResult(response.data.result))
      .then(response => {
        cards = [];
        for (let i = 0; i < result?.length; i++) {
          cards.push(<Card key={i} info={result[i]} />)
        }
      })
  }

  const handlePageClick = (event) => {
    console.log(event.selected)
    getNFTs(event.selected + 1, 30);
  }

  const applyFilter = () => {
    console.log(range);
    fetch(`${api_url}/nft?min=${range.min}&max=${range.max}`)
      .then(response => response.json())
      .then(response => {
        setResult(response.data.result?.docs);
        setRange({ min: 0, max: response.data.maxGems });
        setMaxGems(response.data.maxGems);
      })
      .then(response => {
        cards = [];
        for (let i = 0; i < result?.length; i++) {
          cards.push(<Card key={i} info={result[i]} />)
        }
      })
  }

  const  clearAll = () => {
    getNFTs(1, 30);
  }

  useEffect(() => {
    fetch(`${api_url}/nft?page=${1}&limit=${30}&legendNo=${sortByLegendNo}&gems=${sortByGems}`)
      .then(response => response.json())
      .then(response => setResult(response.data.result?.docs));
  }, [sortByLegendNo, sortByGems])

  useEffect(() => {
    setFlag(0)
    if (input) {
      getSearchedNFTs(input);
    } else {
      getNFTs(1, 30);
    }
    localStorage.removeItem('deduction');
  }, [input]);

  return (
    <div>
      <section className="Home">
        <div className="container">
          <div className="gemText">
            Legendary $GEMS <span className="gradientText">9993 Items</span>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-18 fw-700 text-white text-capitalize mb-5">Filter</div>
            <div>
              <Select className="sortBtn pointr mb-5" placeholder="Sort by"
                options={options} values={[]} onChange={onSortByChange} />
            </div>
          </div>
          <div className="row mx-0 mt-40">
            <div className="col-md-4 px-0 d-none d-lg-block">
              <div className="leftSidebar">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fs-25 fw-700 text-white">Number of $GEMS</div>
                  <div className="fs-18 fw-400 text-white pointer" onClick={()=> clearAll()}>Clear all</div>
                </div>

                <div className="inputRange">
                  <InputRange
                    maxValue={maxGems}
                    minValue={0}
                    value={range}
                    onChange={(value: any) => { setRange(value); }} />
                </div>

                <div className="mt-40"><a className="ApplyBtn" onClick={() => applyFilter()}>Apply Filter</a></div>
                <div className="mt-100 fs-25 fw-700 text-white">Collection</div>

                <div className="tabs">

                  <div className="accordion" id="accordionExample">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div className="text">Accessories</div>
                            <div className="digit d-flex align-items-center">100 <img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {accessoriesArray}
                          </div>
                       
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="accordion" id="accordionExample1">
                    <div className="">
                      <div id="headingOne1">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                            <div className="text">Background</div>
                            <div className="digit d-flex align-items-center">58 <img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne1" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {backgroundArray}
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample2">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                            <div className="text">Clothes</div>
                            <div className="digit d-flex align-items-center">100 <img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne2" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample2">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {clothesArray}
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                     
                  <div className="accordion" id="accordionExample3">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
                            <div className="text">Club</div>
                            <div className="digit d-flex align-items-center">9 <img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne3" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample3">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {clubArray}
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample4">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" data-target="#collapseOne4" aria-expanded="true" aria-controls="collapseOne4">
                            <div className="text">Ear</div>
                            <div className="digit d-flex align-items-center">5<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne4" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {earArray}
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample5">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne5" aria-expanded="true" aria-controls="collapseOne5">
                            <div className="text">Fourth Club</div>
                            <div className="digit d-flex align-items-center">1<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne5" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample5">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {fourthClubArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample6">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne6" aria-expanded="true" aria-controls="collapseOne6">
                            <div className="text">Hair</div>
                            <div className="digit d-flex align-items-center">100<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne6" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample6">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {hairArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample7">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne7" aria-expanded="true" aria-controls="collapseOne7">
                            <div className="text">Legend</div>
                            <div className="digit d-flex align-items-center">33<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne7" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample7">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {legendArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample8">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne8" aria-expanded="true" aria-controls="collapseOne8">
                            <div className="text">Second Club</div>
                            <div className="digit d-flex align-items-center">5<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne8" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample8">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {secondClubArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample9">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne9" aria-expanded="true" aria-controls="collapseOne9">
                            <div className="text">Special Club Item</div>
                            <div className="digit d-flex align-items-center">10<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne9" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample9">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {specialClubItemArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample10">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne10" aria-expanded="true" aria-controls="collapseOne10">
                            <div className="text">Special Edition</div>
                            <div className="digit d-flex align-items-center">25<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne10" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample10">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {specialEditionArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample11">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne11" aria-expanded="true" aria-controls="collapseOne11">
                            <div className="text">Special Legend Item</div>
                            <div className="digit d-flex align-items-center">34<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne11" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample11">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {specialLegendItemArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample12">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne12" aria-expanded="true" aria-controls="collapseOne12">
                            <div className="text">Special Vibes</div>
                            <div className="digit d-flex align-items-center">25<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne12" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample12">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {specialVibesArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample13">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne13" aria-expanded="true" aria-controls="collapseOne13">
                            <div className="text">Tattoos</div>
                            <div className="digit d-flex align-items-center">100<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne13" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample13">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {tattoosArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample14">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne14" aria-expanded="true" aria-controls="collapseOne14">
                            <div className="text">Third Club</div>
                            <div className="digit d-flex align-items-center">4<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne14" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample14">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {thirdClubArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample15">
                    <div className="">
                      <div id="headingOne">
                        <h2 className="mb-0">
                          <a className="tab-filter mb-20" type="button" data-toggle="collapse" 
                          data-target="#collapseOne15" aria-expanded="true" aria-controls="collapseOne15">
                            <div className="text">Vibes</div>
                            <div className="digit d-flex align-items-center">38<img className="ml-3" src={arrowRight} alt="" /></div>
                          </a>
                        </h2>
                      </div>
                      <div id="collapseOne15" className="collapse" aria-labelledby="headingOne" 
                      data-parent="#accordionExample15">
                        <div className="tabsData">
                          <div className="position-relative">
                            <i className="fas fa-search"></i>
                            <input type="search" />
                          </div>
                          <div className="tabsScroll">
                          {vibesArray}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fs-25 fw-700 text-white mb-30">On Sale</div>
                  <div className="tab-filter">
                    <div className="text">Accessories</div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-8 px-0">
              <div className="card-section">
                {cards}
                <div className="home-pagination">
                  <ReactPaginate
                    nextLabel="Next>"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={334}
                    previousLabel="<Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home