import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import cross from '../../assets/cross.svg';
import cross1 from '../../assets/cross-circle.svg';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Web3 from 'web3';
import links from '../../config/constants';
let api_url = process.env.REACT_APP_BACKEND_URL;
let web3: Web3 | undefined = undefined;
let STAKING_CONTRACT_ADDRESS: any = links.STAKING_CONTRACT_ADDRESS;
let STAKING_CONTRACT_ABI: any = links.STAKING_CONTRACT_ABI;

const Cart = ({ setFlag, userAddress, setCartItemsCount }) => {
    const [cartItems, setCartItems] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [userNFTs, setUserNFTs] = useState<any>([]);
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleOpen1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleOpen2 = () => setShow2(true);
    const [isLoading, setIsLoading] = useState(true);
    const [availableGems, setAvailableGems] = useState(0);
    const [newRewardGems, setNewRewardGems] = useState(0);

    const getCartItems = () => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        setCartItemsCount(cartItems?.length);
        let total = 0;
        for (let i = 0; i < cartItems?.length; i++) {
            total += cartItems[i].price * Number(cartItems[i].quantity);
        }
        setTotal(total);
        setCartItems(cartItems);
    }

    const removeCartItem = (index) => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        cartItems.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        getCartItems();
    }

    const initializeContract = async (abi, address) => {
        const web3 = new Web3(Web3.givenProvider)
        let contract = await new web3.eth.Contract(abi, address)
        return contract;
    }

    const getUserNFTs = async (address) => {
        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        let stakedAssets = await contract_instance.methods.getStakedTokens(address).call();

        fetch(`${api_url}/staking/filter-nfts`, {
            body: JSON.stringify({ stakedAssets }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
            method: 'POST',
        })
            .then((response) => response.json())
            .then((response) => {
                setUserNFTs(response.data.unstakedNFTs.concat(response.data.stakedNFTs));
            });
    }

    const handleCheckoutClick = () => {
        if (cartItems.length < 1) {
            handleOpen2();
        } else if ((availableGems + newRewardGems) < total) {
            handleOpen1();
        } else {
            handleOpen();
        }
    }

    //Comme

    const handleFilterByGems = () => {
        navigate("/nft-checkout", { replace: true });
    }

    const getRewardGems = async () => {
        fetch(`${api_url}/claim/record`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
        })
            .then((response) => response.json())
            .then((response) => response.data.record.gems - response.data.record.usedGems)
            .then((response) => setNewRewardGems(response))

    }

    useEffect(() => {
        let gems = 0
        for (let i = 0; i < userNFTs?.length; i++) {
            setIsLoading(true);
            gems += userNFTs[i]?.noOfGems;
            setAvailableGems(gems);
            if (i == (userNFTs?.length - 1)) {
                setIsLoading(false);
            }
        }
    }, [userNFTs])

    useEffect(() => {
        try {
            if (userAddress) {
                getUserNFTs(userAddress);
                getRewardGems()
            } else {
                setUserNFTs([]);
            }
        } catch (e) { }
        localStorage.removeItem('deduction');
        getCartItems();
        setFlag(3);
    }, [userAddress])

    let cartArray = [];
    let cartArrayMobile = [];
    let items = 0;
    for (let i = 0; i < cartItems?.length; i++) {
        items += Number(cartItems[i]?.quantity);
        cartArray.push(
            <div className="cartCard d-none d-lg-block  mb-30" key={i}>
                <div className="row justify-content-between align-items-center mx-0">
                    <div className="col-lg-12 px-0 d-none d-lg-block">
                        <div className="d-lg-flex justify-content-between">
                            <div className="d-flex mb-3">
                                <div className="cart-img">
                                    <img src={cartItems[i]?.image} alt="" />
                                </div>
                                <div className="ml-lg-4 ml-2 d-flex flex-column justify-content-between w-100">
                                    <div className="description">
                                        {cartItems[i]?.desc}<br />
                                    </div>
                                    <div className="pointer d-inline-block d-lg-none d-block" >
                                        <img src={cross} onClick={() => removeCartItem(i)} alt="" />
                                    </div>
                                    <div className="d-flex w-100 justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="fs-16 fw-700 textWhite70 mr-2">Quantity: </div>
                                            <div className="fs-25 text-white fw-700">{cartItems[i]?.quantity}</div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="fs-16 fw-700 textWhite70 mr-2">Left Only:
                                            </div>
                                            <div className="fs-25 text-white fw-700">{cartItems[i]?.left}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex d-lg-block justify-content-between">
                                <div className="fs-16 fw-700 textWhite70">Price</div>
                                <div className="fs-25 fw-700 text-white">{cartItems[i]?.price} Gems</div>
                            </div>
                            <div className="pointer d-none d-lg-block d-inline-block"><img src={cross} onClick={() => removeCartItem(i)} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

        )

        cartArrayMobile.push(
            <div className="cartCard d-block d-lg-none mb-30" key={i}>
                <div className="row justify-content-between align-items-center mx-0">
                    <div className="col-lg-12 px-0 d-block d-lg-none" >
                        <div className="d-flex mb-3">
                            <div className="cart-img">
                                <img src={cartItems[i]?.image} alt="" />
                            </div>
                            <div className="ml-lg-4 ml-2 d-flex justify-content-between w-100">
                                <div className="description">
                                    {cartItems[i]?.desc}<br />
                                </div>
                                <div className="pointer d-inline-block d-lg-none d-block"><img src={cross} onClick={() => removeCartItem(i)} alt="" /></div>

                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="fs-16 fw-700 textWhite70">Quantity: </div>
                            <div className="fs-25 text-white fw-700">{cartItems[i]?.quantity}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="fs-16 fw-700 textWhite70">Left Only:
                            </div>
                            <div className="fs-25 text-white fw-700">{cartItems[i]?.left}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="fs-16 fw-700 textWhite70">Price</div>
                            <div className="fs-25 fw-700 text-white">{cartItems[i]?.price} Gems</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="viewCart">
                <div className="container">
                    <div className="row mx-0">
                        <div className="col-lg-7 ">
                            <div className="fs-36 fw-700 text-white before mb-80">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="fs-36 fw-700 text-white">Cart</div>
                                    <div className="btn legendryBtn-green">
                                        <span className="innerText">You Have {availableGems + newRewardGems} Legendary Gems</span>
                                    </div>
                                </div>
                            </div>
                            {/* {availableGems + newRewardGems} */}
                            {cartArrayMobile}
                            {cartArray}
                        </div>
                        <div className="col-lg-5 ">
                            <div className="fs-36 fw-700 before text-white mb-80">Order Summary</div>
                            <div className="cartCard">
                                <div className="innerBox mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Subtotal</div>
                                    <div className="fs-16 fw-700 textGreen">{total} Gems</div>
                                </div>
                                <div className="innerBox mb-30">
                                    <div className="fs-16 fw-700 textWhite70">Items</div>
                                    <div className="fs-16 fw-700 text-white">{items}</div>
                                </div>
                                <div className="innerBox mb-40">
                                    <div className="fs-16 fw-700 textWhite70">Total</div>
                                    <div className="fs-16 fw-700 textGreen">{total} Gems</div>
                                </div>
                                <div className="mb-30">
                                    {!isLoading && <a className="connectBtn" onClick={() => handleCheckoutClick()}>Checkout</a>}
                                    {isLoading && <a className="connectBtn" >Loading...</a>}
                                </div>
                                <div className="mb-30">
                                    <Link to='/store'>
                                        <a className="green-btn-outline">Continue Shopping</a>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal
                    show={show}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose}
                    dialogClassName="customModal"
                >
                    <div className="modal-head">
                        <div className="fs25 fw-700 text-white">Checkout</div>
                        <div className="pointer" onClick={() => handleClose()}><img src={cross} alt="" /></div>
                    </div>
                    <div className="modal-body">
                        <div className='mb-3'><a className='connectBtn fw-700' onClick={() => handleFilterByGems()}>Filter By Gems</a></div>
                        <div className='mb-3'>
                            <Link to='/checkout'>
                                <a className='connectBtn fw-700'>Auto-deduct</a>
                            </Link>
                        </div>
                    </div>
                </Modal>

                <Modal
                    show={show1}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose1}
                    dialogClassName="customModal"
                >

                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">Not Enough Gems to complete <br />
                            the purchase !</div>
                        <div><a onClick={() => handleClose1()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>

                <Modal
                    show={show2}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose2}
                    dialogClassName="customModal"
                >

                    <div className="modal-body">
                        <div className="icon">
                            <img src={cross1} alt="" />
                        </div>
                        <div className="fs-18 fw-700 text-white text-center my-4">No Item in the Cart!</div>
                        <div><a onClick={() => handleClose2()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default Cart