import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../assets/logo-1.png";
import navMessage from "../../assets/nav-message-icon.svg";
import basketIcon from "../../assets/nav-baskt-icon.svg";
import breadCrumb from "../../assets/breadCrumb-path.svg";
import searchIcon from "../../assets/search.svg";
import toggleIcon from "../../assets/Menu.svg";
import navLogo from "../../assets/logo-2.svg";
import discord from "../../assets/discord.svg";
import twitter from "../../assets/twitter.svg";
import insta from "../../assets/insta.svg";
import mail from "../../assets/mail.svg";
import cart from "../../assets/cart.svg";
import cross from "../../assets/cross.svg";
import tick from "../../assets/tick-green.svg";
import cross1 from "../../assets/cross-circle.svg";
import Web3 from "web3";
import links from "../../config/constants";
import { Modal } from "react-bootstrap";
let STAKING_CONTRACT_ADDRESS: any = links.STAKING_CONTRACT_ADDRESS;
let STAKING_CONTRACT_ABI: any = links.STAKING_CONTRACT_ABI;
let web3: Web3 | undefined = undefined;
let api_url = process.env.REACT_APP_BACKEND_URL;

const Header = ({
  setInput,
  setInputGems,
  flag,
  setUserAddress,
  address2,
  setRewardGems,
  cartItemsCount,
  setCartItemsCount
}) => {
  const [userAddress, setAddress] = useState("");
  const [legendNo, setLegendNo] = useState();
  const [cartItemCount, setcartItemCount] = useState(0);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleOpen1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleOpen2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleOpen3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleOpen4 = () => setShow4(true);
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const handleChange = (event) => {
    setLegendNo(event.target.value);
    if(!event.target.value){
      setInput();
    }
  }

  const onSearchClick = () => {
    setInput(legendNo);
  };

  const handleGemsChange = (event) => {
    setInputGems(event.target.value);
  };

  const onHomeClick = () => {
    setIsActive1(true);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
  };

  const onLegendaryClick = () => {
    setIsActive1(false);
    setIsActive2(true);
    setIsActive3(false);
    setIsActive4(false);
  };

  const onGemVaultClick = () => {
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(true);
    setIsActive4(false);
  };

  const onStoreClick = () => {
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(true);
  };

  const onCartClick = () => {
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
  };

  const getCartItems = () => {
    let cartItems = JSON.parse(localStorage.getItem('cartItems'));
    setCartItemsCount(cartItems?.length);
}

  useEffect(() => {
    setAddress(localStorage.getItem("userAddress"));
    setUserAddress(localStorage.getItem("userAddress"));
    getCartItems();
    getRewardGems();
    if (flag == 0) {
      setIsActive1(false);
      setIsActive2(true);
      setIsActive3(false);
      setIsActive4(false);
    } else if (flag == 1) {
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(true);
      setIsActive4(false);
    } else if (flag == 2) {
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(true);
    }else if (flag == 3) {
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(false);
    } else if (flag == 8) {
      setIsActive1(true);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(false);
    }
  
  }, [address2]);

  const handleSignMessage = async (user: any) => {
    let walletAddress = user.walletAddress;
    try {
      const signature = await web3!.eth.personal.sign(
        `I am signing my one-time nonce: ${user.nonce}`,
        walletAddress,
        "" // MetaMask will ignore the password argument here
      );

      return { walletAddress, signature };
    } catch (err) {
      throw new Error("You need to sign the message to be able to log in.");
    }
  };

  const handleSignup = async (publicAddress: string) => {
    let signature;
    let nonce = Math.floor(Math.random() * 1000000);
    try {
      signature = await web3!.eth.personal.sign(
        `I am signing-up using my one-time nonce: ${nonce}`,
        publicAddress,
        "" // MetaMask will ignore the password argument here
      );
    } catch (err) {
      throw new Error("You need to sign the message to be able to Sign Up.");
    }

    return fetch(`${api_url}/users`, {
      body: JSON.stringify({ publicAddress, signature, nonce }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => response.data.result);
  };

  const handleAuthenticate = async (user: any) => {
    let walletAddress = user.walletAddress;
    let signature = user.signature;
    return fetch(`${api_url}/users/auth`, {
      body: JSON.stringify({ walletAddress, signature }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((response) => response.json());
  };

  const handleClick = async () => {
    // Check if MetaMask is installed
    if (!(window as any).ethereum) {
      window.alert("Please install MetaMask first.");
      return;
    }

    if (!web3) {
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3((window as any).ethereum);
      } catch (error) {
        window.alert("You need to allow MetaMask.");
        return;
      }
    }

    const coinbase = await web3.eth.getCoinbase();
    if (!coinbase) {
      window.alert("Please activate MetaMask first.");
      return;
    }

    const publicAddress = coinbase.toLowerCase();

    fetch(`${api_url}/users?walletAddress=${publicAddress}`)
      .then((response) => response.json())
      .then((response) => response.data.result)
      .then((user) => (user ? user : handleSignup(publicAddress)))
      .then((user) => handleSignMessage(user))
      .then((user) => handleAuthenticate(user))
      .then((token) => {
        localStorage.setItem("gemToken", token.accessToken);
        localStorage.setItem("userAddress", publicAddress);
        localStorage.setItem("cartItems", "[]");
        setAddress(publicAddress);
        setUserAddress(publicAddress);
      });
  };

  const initializeContract = async (abi, address) => {
    const web3 = new Web3(Web3.givenProvider)
    let contract = await new web3.eth.Contract(abi, address)
    return contract;
  }

  const claim = async () => {
    const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
    let stakedAssets = await contract_instance.methods.getStakedTokens(userAddress).call();
    console.log(stakedAssets);
    fetch(`${api_url}/claim/`, {
      body: JSON.stringify({ stakedAssets }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("gemToken")}`,
      },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data.status == 203) {
          //Cannot claim gems without assets
          handleOpen1();
        } else if (response.data.status == 201) {
          //Reward already claimed!
          handleOpen2();
        } else if (response.data.status == 204) {
          //success
          handleOpen4();
          setRewardGems(response.data.gems);
          setShowClaimButton(false);
        } else {
          console.log(response.data);
          //An Error Occured while claiming Gems
          handleOpen3();
        }
      });
  };

  const getRewardGems = async () => {
    fetch(`${api_url}/claim/record`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("gemToken")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status == 200) {
          setShowClaimButton(false);
        } else {
          setShowClaimButton(true);
        }
      });
  };

  const disconnect = () => {
    localStorage.removeItem("userAddress");
    localStorage.removeItem("gemToken");
    localStorage.removeItem("deduction");
    localStorage.removeItem("cartItems");
    setAddress("");
    setUserAddress("");
  };


  return (
    <div className={flag == 8  ?  "bg-transparent pb-0" : "topHeader" } >
      <div className={flag == 8 ? "topNav mb-0 mb-n80 fixed-top bg-blur" : "topNav"}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-trasparent">
          <div className="container">
            <div className="horizontalLine">
              <ul className="smallScreen d-lg-none">
                <li className="mr-auto">
                  <a className="navbar-brand" href="https://www.houseoflegends.art/">
                    <img src={logo} style={{ height: "10px" }} alt="" />
                  </a>
                </li>
                <li>
                  <a className="" href="#">
                    <img src={navMessage} alt="" />
                  </a>
                </li>
                <li>
                  <a className="" href="#">
                    <img src={basketIcon} alt="" />
                  </a>
                </li>
              </ul>
              <button
                className="navbar-toggler float-right border-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="">
                  <img src={toggleIcon} alt="" />
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <div className="small-header d-flex d-lg-none">
                  <div className="mobile-sidebar-logo">
                    <img src={logo} alt="" />
                  </div>
                  <button
                    className="mobileCross"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <img src={cross} alt="" />
                  </button>
                </div>
                <div className="d-none d-lg-block">
                  <a className="navbar-brand pointer" href="https://www.houseoflegends.art/gem-vault" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="" height={20} />
                  </a>
                </div>
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item" onClick={() => onHomeClick()}>
                    {/* <NavLink
                      to="/"
                      className={isActive1 ? "nav-link active" : "nav-link"}
                    >
                      <div className="d-block d-lg-none fs-10 fw-400 textWhite70">
                        Pages
                      </div>
                      Home <span className="sr-only">(current)</span>
                    </NavLink> */}
                  </li>
                  <li
                    className="nav-item mx-15"
                    onClick={() => onLegendaryClick()}
                  >
                    <NavLink
                      to="/gems"
                      className={isActive2 ? "nav-link active" : "nav-link"}
                    >
                      $Gems
                    </NavLink>
                  </li>
                  <li
                    className="nav-item mx-15"
                    onClick={() => onGemVaultClick()}
                  >
                    <NavLink
                      to="/my-vault"
                      className={isActive3 ? "nav-link active" : "nav-link"}
                    >
                      Gem Vault
                    </NavLink>
                  </li>
                  <li className="nav-item" onClick={() => onStoreClick()}>
                    <NavLink
                      to="/store"
                      className={isActive4 ? "nav-link active" : "nav-link"}
                    >
                      Legendary Store
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.houseoflegends.art/shop"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link"
                    >
                      Merch
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => onCartClick()}>
                    <NavLink to='/cart' className="nav-link textGreen">
                      Cart ({cartItemsCount})
                    </NavLink>
                  </li>
                </ul>
                {/* <ul className="navbar-nav ml-auto d-none d-lg-block">
                  <li className="nav-item"></li>
                  <li className="nav-item mx-15"></li>
                  <li className="nav-item"></li>
                </ul> */}
                {/* <ul className="SearchBar">
                  <input placeholder="Search..." type="search" />
                </ul> */}
                <ul className="socialIcons">
                  {/* <li>
                    <a href={links.discord} target="_blank" rel="noopener noreferrer" className="pointer">
                      <img src={discord} alt="" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href={links.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pointer"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={links.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pointer"
                    >
                      <img src={insta} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={links.discord}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pointer"
                    >
                      <img src={discord} alt="" />
                    </a>
                  </li>
                  {/* <li>
                    <NavLink to="/cart">
                      <a className="pointer">
                        <img src={cart} alt="" />
                      </a>
                    </NavLink>
                  </li> */}
                </ul>
                <div className="privacy-terms fs-18 textWhite80 fw-400 d-block d-lg-none">
                  <div className="sm-horizontal"></div>
                  Privacy Policy | Terms & <br /> Conditions
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {flag !== 8 && 
      (
        <div className="container">
          <div className="d-lg-flex justify-content-between align-items-center">
            <div className="breadCrumb">
              <NavLink to="/">
                <div className="parent pointer"> Home</div>
              </NavLink>
              <img src={breadCrumb} className="mx-2" alt="" />
              {flag == 0 && <div className="child"> Legendary $GEMS</div>}
              {flag == 1 && <div className="child"> My Vault</div>}
              {flag == 2 && <div className="child"> Store</div>}
              {flag == 3 && <div className="child"> Cart</div>}
              {flag == 4 && <div className="child"> Item Checkout</div>}
              {flag == 5 && <div className="child"> Product Details</div>}
              {flag == 6 && <div className="child"> Wishlist</div>}
              {flag == 7 && <div className="child"> Faq</div>}
            </div>
            <div className="d-flex">
              {!userAddress && (
                <a
                  onClick={() => handleClick()}
                  className="btn connectBtn pointer mb-5"
                >
                  <span className="connectBtn-innerText">Connect Wallet</span>
                </a>
              )}
              {flag == 1 && userAddress && showClaimButton && (
                <a
                  onClick={() => claim()}
                  className="btn connectBtn pointer mb-2 mr-3"
                >
                  <span className="connectBtn-innerText">Claim free $GEMS</span>
                </a>
              )}
              {userAddress && (
                <div className="dropdown">
                  <button
                    className="btn connectBtn dropdown-toggle mb-5"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="connectBtn-innerText">
                      {userAddress.slice(0, 5)}...
                      {userAddress.slice(
                        userAddress.length - 5,
                        userAddress.length
                      )}
                    </span>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" onClick={() => disconnect()}>
                      Disconnect
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          {flag == 0 && (
            <div className="input-with-icon">
              <img src={searchIcon} alt="" />
              <input
                type="text"
                className="form-control"
                onChange={handleChange}
                placeholder="Search by Legend #"
              />
              <button
                className="btn search-btn"
                onClick={() => onSearchClick()}
              >
                Search
              </button>
            </div>
          )}
          {flag == 2 && (
            <div className="input-with-icon">
              <img src={searchIcon} alt="" />
              <input
                type="text"
                className="form-control"
                onChange={handleGemsChange}
                placeholder="Search by Number of $GEMS"
              />
              <button className="btn search-btn">Search</button>
            </div>
          )}
          {flag == 7 && (
            <div className="input-with-icon">
              <img src={searchIcon} alt="" />
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <button className="btn search-btn">Search</button>
            </div>
          )}
        </div>
      )}

      <Modal
        show={show1}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose1}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={cross1} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">
            Cannot claim gems without assets!
          </div>
          <div>
            <a onClick={() => handleClose1()} className="btn connectBtn d-flex">
              Okay
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        show={show2}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose2}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={cross1} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">
            Gems already claimed!
          </div>
          <div>
            <a onClick={() => handleClose2()} className="btn connectBtn d-flex">
              Okay
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        show={show3}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose3}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={cross1} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">
            An Error Occured while claiming Gems!
          </div>
          <div>
            <a onClick={() => handleClose3()} className="btn connectBtn d-flex">
              Okay
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        show={show4}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose4}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={tick} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">
            Gems claimed successfully!
          </div>
          <div>
            <a onClick={() => handleClose4()} className="btn connectBtn d-flex">
              Okay
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
