let FOURTH_CLUB = [
    { label: 'Non Meatless Mondays', count: 327 },
]

let THIRD_CLUB = [
    { label: 'Non Meatless Mondays', count: 1637 },
    { label: 'House of Legends Poets & Storytellers Society', count: 655 },
    { label: 'H.O.L Order of the Human Right Defenders', count: 327 },
    { label: 'League of Legendary Athletes', count: 327 },
]

let SECOND_CLUB = [
    { label: 'The Great House of Legends Sorority', count: 2616 },
    { label: 'Non Meatless Mondays', count: 2292 },
    { label: 'House of Legends Poets & Storytellers Society', count: 1309 },
    { label: 'H.O.L Order of the Human Rights Defenders', count: 328 },
    { label: "The Queen's Afternoon Tea Party", count: 326 },
]

let CLUB = [
    { label: 'H.O.L Laboratory for the Advancement of Scientific Research', count: 1966 },
    { label: 'H.O.L Order of the Human Rights Defenders', count: 1966 },
    { label: 'House of Legends Studio of the Fine Arts', count: 1632 },
    { label: "Secret Circle of the President's Seal", count: 982 },
    { label: "The Queen's Afternoon Tea Party", count: 982 },
    { label: "The Legendary Company of Performing Artists", count: 981 },
    { label: "League of Legendary Athletes", count: 658 },
    { label: "Association of Legendary Psychoanalysts", count: 327 },
    { label: "The Great House of Legends Sorority", count: 327 },
]

let EAR = [
    { label: 'Airpod', count: 96 },
    { label: 'Pierced', count: 76 },
    { label: 'Bling', count: 70 },
    { label: 'Hearing Aid', count: 69 },
    { label: "The Things We Do For Love", count: 16 },
]

let SPECIAL_CLUB_ITEM = [
    { label: 'The American Bandana', count: 136 },
    { label: 'The Raised Fist', count: 118 },
    { label: 'The Great H.O.L Sorority Flag', count: 99 },
    { label: 'H.O.L Labs Access Card', count: 90 },
    { label: "The Quill", count: 86 },
    { label: "The Artist's Paintbrush", count: 74 },
    { label: "The Royal Tea Cup", count: 62 },
    { label: "League of Legendary Athletes Sweatband", count: 45 },
    { label: "The Golden Microphone", count: 34 },
    { label: "The Presidential Red Phone", count: 26 },
]

let SPECIAL_VIBES = [
    { label: "High Vibrations", count: 30},
    { label: "Just Arrived", count: 30},
    { label: "SO Relaxed", count: 30},
    { label: "Space Vibes", count: 30},
    { label: "Gameday Energy", count: 19},
    { label: "Full Moon Elation", count: 6},
    { label: "Childish Inspiration", count: 5},
    { label: "Indoor Camping", count: 5},
    { label: "According to Plan", count: 1},
    { label: "By The People, For The People", count: 1},
    { label: "Compassion is Strength", count: 1},
    { label: "Equanimity", count: 1},
    { label: "Getting that Mars Tan", count: 1},
    { label: "Honoring The Dead", count: 1},
    { label: "Hungover", count: 1},
    { label: "Inspiration for Ages", count: 1},
    { label: "Laser Focused", count: 1},
    { label: "Lightning Storm", count: 1},
    { label: "Mixed Up", count: 1},
    { label: "One More Thing...", count: 1},
    { label: "Radiating", count: 1},
    { label: "The Christmas Spirit!", count: 1},
    { label: "Vacuuming", count: 1},
    { label: "Wildly Curious", count: 1},
    { label: "You’re Getting Very Sleepy", count: 1},
]

let SPECIAL_EDITION = [
    { label: "Fresh Out of the Portal", count: 30},
    { label: "The House of Legends Resort & Spa", count: 30},
    { label: "The House of Legends Space Program", count: 30},
    { label: "The House of Legends Spring Retreat", count: 30},
    { label: "GO Legends!", count: 19},
    { label: "The Great Sorority’s Full Moon Party", count: 6},
    { label: "Anna’s Special Edition", count: 5},
    { label: "Oprah’s Legendary Sleepover", count: 5},
    { label: "A Self Portrait", count: 1},
    { label: "Diana in Wonderland", count: 1},
    { label: "Einstein C137", count: 1},
    { label: "Freddie Breaking Free", count: 1},
    { label: "Frida De Los Muertos", count: 1},
    { label: "His Holiness The Legend", count: 1},
    { label: "Home Remedy for a Broken Heart", count: 1},
    { label: "How To Change The World", count: 1},
    { label: "Hypnotherapy", count: 1},
    { label: "Inner Balance", count: 1},
    { label: "Tesla, God of Thunder", count: 1},
    { label: "The Legend of Christmas is Real", count: 1},
    { label: "The Man from Mars", count: 1},
    { label: "The Most Legendary Smile in History", count: 1},
    { label: "The Original Thief", count: 1},
    { label: "The Original X-Woman", count: 1},
    { label: "The Presidential Portrait", count: 1},
]

let SPECIAL_LEGEND_ITEM = [
    { label: 'The Cigar', count: 100},
    { label: 'A Banana', count: 66},
    { label: 'The Vape', count: 54},
    { label: 'The Bowl', count: 51},
    { label: "What's Up Doc?", count: 44},
    { label: "Gandhi's Lathi", count: 35},
    { label: "Maradona's Ball", count: 33},
    { label: 'The Palm of Madiba', count: 28},
    { label: "Tesla's Electric Ball", count: 27},
    { label: 'The Falcon 9', count: 25},
    { label: 'The Atom', count: 24},
    { label: 'The Podium', count: 24},
    { label: 'The Melting Clock', count: 22},
    { label: "Churchill's Cigar", count: 19},
    { label: "Campbell's Soup", count: 16},
    { label: 'Ready to Fight', count: 16},
    { label: 'The Apple', count: 16},
    { label: 'Corgi <3', count: 15},
    { label: 'The British Red Cross Badge', count: 15},
    { label: "Galileo's Telescope", count: 13},
    { label: 'The Hammer of Justice', count: 13},
    { label: 'The Radium Stone', count: 13},
    { label: "Barry's Basketball", count: 12},
    { label: "The Dog", count: 12},
    { label: "You Get a Car", count: 12},
    { label: "Freddie's Famous Microphone", count: 11},
    { label: 'Heart Hands', count: 11},
    { label: 'Just Some Birthday Cake', count: 11},
    { label: "Leonard Cohen's Guitar", count: 11},
    { label: 'The Dove', count: 10},
    { label: 'The Bus Ticket', count: 9},
    { label: "Abe's Theater Binoculars", count: 7},
    { label: "Frida's Monkey", count: 7},
    { label: 'My Birthday Cake :)', count: 4},
    
]

let LEGEND = [
    { label: "Andy Warhol", count: 333},
    { label: "Barack Obama", count: 333},
    { label: "Churchill", count: 333},
    { label: "Dali", count: 333},
    { label: "Einstein", count: 333},
    { label: "Elon Musk", count: 333},
    { label: "Freddie Mercury", count: 333},
    { label: "Freud", count: 333},
    { label: "Frida Kahlo", count: 333},
    { label: "Galileo", count: 333},
    { label: "Gandhi", count: 333},
    { label: "JFK", count: 333},
    { label: "Leonard Cohen", count: 333},
    { label: "Lincoln", count: 333},
    { label: "MLK", count: 333},
    { label: "Mandela", count: 333},
    { label: "Maradona", count: 333},
    { label: "Marie Curie", count: 333},
    { label: "Marilyn Monroe", count: 333},
    { label: "Michelle Obama", count: 333},
    { label: "Muhammad Ali", count: 333},
    { label: "Oprah", count: 333},
    { label: "Picasso", count: 333},
    { label: "Princess Diana", count: 333},
    { label: "RBG", count: 333},
    { label: "Rosa Parks", count: 333},
    { label: "Steve Jobs", count: 333},
    { label: "Tesla", count: 333},
    { label: "The Queen", count: 333},
    { label: "Van Gogh", count: 333},
    { label: "Mona Lisa", count: 1},
    { label: "Santa Claus", count: 1},
    { label: "The Dalai Lama", count: 1},
]

let VIBES = [
    { label: "American Pride", count: 136},
    { label: "Women Unite!", count: 99},
    { label: "Eureka!", count: 90},
    { label: "Power to the People", count: 89},
    { label: "Writer's Daydream", count: 86},
    { label: "In Flying Colors", count: 74},
    { label: "Four O'Clock Somewhere", count: 62},
    { label: "Feel the Burn!", count: 45},
    { label: "A Step For Change", count: 35},
    { label: "World's a Stage", count: 34},
    { label: "Play With the Best", count: 33},
    { label: "A Hand For Peace", count: 28},
    { label: "Buzzing", count: 27},
    { label: "POTUS", count: 26},
    { label: "Dare to Dream Big", count: 25},
    { label: "A Moment to Dream", count: 24},
    { label: "Cosmic Moment", count: 24},
    { label: "Out of Time", count: 22},
    { label: "Like a Boss", count: 19},
    { label: "Fight Night", count: 16},
    { label: "McIntosh", count: 16},
    { label: "Yummy", count: 16},
    { label: "Hope for Humanity", count: 15},
    { label: "Royal Companion", count: 15},
    { label: "Dangerously Beautiful", count: 13},
    { label: "Justice is Served", count: 13},
    { label: "Start by Observing", count: 13},
    { label: "Everybody Gets a Car", count: 12},
    { label: "Some Hoops", count: 12},
    { label: "Floating Around in Ecstasy", count: 11},
    { label: "I Feel Like Singing", count: 11},
    { label: "Just Love", count: 11},
    { label: "Secret Chords", count: 11},
    { label: "Peaceful", count: 10},
    { label: "Groundbreaking", count: 9},
    { label: "Mood For Drama", count: 7},
    { label: "Real Friendship", count: 7},
    { label: "Happy Bday to Me", count: 4},
]

let BACKGROUND = [
    { label: "Starry Night", count: 596},
    { label: "Gold Marble", count: 588},
    { label: "Teal", count: 572},
    { label: "Persian Green", count: 524},
    { label: "Gold Yellow", count: 409},
    { label: "Lemon Yellow", count: 393},
    { label: "Mint Green", count: 375},
    { label: "Turquoise", count: 355},
    { label: "Aquamarine", count: 335},
    { label: "The House of Legends Estate", count: 284},
    { label: "Deep Ocean", count: 283},
    { label: "Sunset", count: 282},
    { label: "Sunset", count: 282},
    { label: "Yellow Orange", count: 270},
    { label: "Care Bears", count: 269},
    { label: "Northern Lights", count: 267},
    { label: "Lavender", count: 261},
    { label: "Pastel Yellow", count: 261},
    { label: "Sunrise Kingdom", count: 258},
    { label: "Magenta", count: 256},
    { label: "Punch", count: 254},
    { label: "Bubblegum", count: 253},
    { label: "Cream", count: 250},
    { label: "Peach", count: 250},
    { label: "Salmon", count: 249},
    { label: "Pastel Pink", count: 245},
    { label: "Light Blue", count: 242},
    { label: "Light Azure", count: 237},
    { label: "Pastel Indigo", count: 222},
    { label: "Pale Blue", count: 209},
    { label: "In The Clouds", count: 137},
    { label: "In The Clouds", count: 135},
    { label: "Deep Space", count: 30},
    { label: "Steams & Chill", count: 30},
    { label: "The Golden Hour", count: 30},
    { label: "The Portal", count: 30},
    { label: "Legends Stadium", count: 19},
    { label: "In the Clouds", count: 18},
    { label: "Moonlight", count: 6},
    { label: "Every Child is an Artist", count: 5},
    { label: "Pillow Fight", count: 5},
    { label: "Asgard Night", count: 1},
    { label: "Basically Everybody", count: 1},
    { label: "Cubism", count: 1},
    { label: "Da Vinci Black", count: 1},
    { label: "Down the Rabbit Hole", count: 1},
    { label: "Holy Golden", count: 1},
    { label: "Marie's Lab", count: 1},
    { label: "Nirvana", count: 1},
    { label: "The Jolly Red", count: 1},
    { label: "The Living Room", count: 1},
    { label: "The Multiverse", count: 1},
    { label: "The Red Planet", count: 1},
    { label: "The Subconscious Mind", count: 1},
    { label: "The Sugar Skull", count: 1},
    { label: "The Vault", count: 1},
    { label: "The White House", count: 1},
    { label: "Vincent's Room", count: 1},
]

let ACCESSORIES = [
    { label: 'The Queen Accessories 5', count: 74 },
    { label: 'MLK Accessories 4', count: 72 },
    { label: 'Maradona Accessories 1', count: 70 },
    { label: 'Marie Curie Accessories 3', count: 70 },
    { label: 'Michelle Accessories 4', count: 70 },
    { label: 'Steve Jobs Accessories 4', count: 69 },
    { label: 'Tesla Accessories 3', count: 69 },
    { label: 'leonard Cohen Accessories 3', count: 68 },
    { label: 'RGB Accessories 1', count: 68 },
    { label: 'Freud Accessories 4', count: 67 },

    { label: "Mandela Accessories 1", count: 67},
    { label: "Princess Diana Accessories 4", count: 67},
    { label: "Andy Warhol Accessories 5", count: 66},
    { label: "Marilyn Accessories 1", count: 66},
    { label: "Marilyn Accessories 2", count: 66},
    { label: "Picasso Accessories 5", count: 66},
    { label: "Van Gogh Accessories 5", count: 66},
    { label: "Van Gogh Accessories 5", count: 65},
    { label: "Dali Accessories 4", count: 65},
    { label: "Freud Accessories 1", count: 65},
    { label: "Leonard Cohen Accessories 2", count: 65},
    { label: "Rosa Parks Accessories 3", count: 65},
    { label: "Steve Jobs Accessories 5", count: 65},
    { label: "Ali Accessories 4", count: 64},
    { label: "Freud Accessories 3", count: 64},
    { label: "Frida Accessories 4", count: 64},
    { label: "Oprah Accessories 4", count: 64},
    { label: "Einstein Accessories 1", count: 63},
    { label: "Mandela Accessories 2", count: 63},
    { label: "Princess Diana Accessories 1", count: 63},

    { label: "Barack Obama Accessories 3", count: 62},
    { label: "The Queen Accessories 4", count: 62},
    { label: "Galileo Accessories 2", count: 61},
    { label: "Gandhi Accessories 3", count: 61},
    { label: "Van Gogh Accessories 4", count: 61},
    { label: "Churchill Accessories 4", count: 60},
    { label: "Einstein Accessories 3", count: 60},
    { label: "Freddie Accessories 3", count: 60},
    { label: "Freddie Accessories 3", count: 60},
    { label: "Lincoln Accessories 1", count: 60},
    { label: "Barack Obama Accessories 1", count: 59},
    { label: "Elon Musk Accessories 4", count: 59},
    { label: "Galileo Accessories 5", count: 59},
    { label: "Lincoln Accessories 5", count: 59},
    { label: "Dali Accessories 3", count: 58},
    { label: "Frida Accessories 3", count: 58},
    { label: "JFK Accessories 3", count: 58},
    { label: "Marie Curie Accessories 4", count: 58},
    { label: "Michelle Accessories 3", count: 58},
    { label: "Rosa Parks Accessories 2", count: 58},

    { label: "Ali Accessories 1", count: 57},
    { label: "Ali Accessories 3", count: 57},
    { label: "Freud Accessories 5", count: 57},
    { label: "Frida Accessories 2", count: 57},
    { label: "Galileo Accessories 1", count: 57},
    { label: "Galileo Accessories 4", count: 57},
    { label: "JFK Accessories 2", count: 57},
    { label: "JFK Accessories 5", count: 57},
    { label: "Oprah Accessories 3", count: 57},
    { label: "RBG Accessories 2", count: 57},
    { label: "Barack Obama Accessories 4", count: 56},
    { label: "Leonard Cohen Accessories 1", count: 56},
    { label: "Leonard Cohen Accessories 5", count: 56},
    { label: "Mandela Accessories 3", count: 56},
    { label: "Maradona Accessories 4", count: 56},
    { label: "Marie Curie Accessories 2", count: 56},
    { label: "Oprah Accessories 1", count: 56},
    { label: "RBG Accessories 4", count: 56},
    { label: "Tesla Accessories 2", count: 56},
    { label: "Ali Accessories 2", count: 55},

    { label: "Andy Warhol Accessories 1", count: 55},
    { label: "Einstein Accessories 4", count: 55},
    { label: "Elon Musk Accessories 3", count: 55},
    { label: "Freddie Accessories 4", count: 55},
    { label: "Marilyn Accessories 3", count: 55},
    { label: "Picasso Accessories 2", count: 55},
    { label: "RBG Accessories 5", count: 55},
    { label: "Steve Jobs Accessories 2", count: 55},
    { label: "Churchill Accessories 2", count: 54},
    { label: "Dali Accessories 5", count: 54},
    { label: "MLK Accessories 3", count: 54},
    { label: "Maradona Accessories 3", count: 54},
    { label: "Oprah Accessories 5", count: 54},
    { label: "Picasso Accessories 3", count: 54},
    { label: "Tesla Accessories 5", count: 54},
    { label: "The Queen Accessories 2", count: 54},
    { label: "Barack Obama Accessories 2", count: 53},
    { label: "Barack Obama Accessories 5", count: 53},
    { label: "Freddie Accessories 5", count: 53},
    { label: "Gandhi Accessories 5", count: 53},
    { label: "Gandhi Accessories 4", count: 53},
    { label: "Lincoln Accessories 4", count: 53},
    { label: "MLK Accessories 5", count: 53},
    { label: "Marie Curie Accessories 1", count: 53},
    { label: "Michelle Accessories 2", count: 53},
    { label: "Elon Musk Accessories 1", count: 52},
    { label: "Elon Musk Accessories 5", count: 52},
    { label: "Freud Accessories 2", count: 52},
    { label: "Lincoln Accessories 2", count: 52},
    { label: "Oprah Accessories 2", count: 52},
]

let CLOTHES = [
    { label: "Line Drawing No. 3 Teal Shirt", count: 16},
    { label: "The Flamingos Shirt", count: 15},
    { label: "Apple History Buttoned Up Shirt", count: 12},
    { label: "Boss RBG White", count: 12},
    { label: "Galileo Striped Shirt", count: 12},
    { label: "Hello Buttoned Up Shirt", count: 12},
    { label: "Not About Dogecoin Yellow Shirt", count: 12},
    { label: "On The Beaches Big White Shirt", count: 12},
    { label: "Sanity Gray Shirt", count: 12},

    { label: "Argentina National Team", count: 11},
    { label: "Change The World Sunset Shirt", count: 11},
    { label: "Elon's Night Skies Kimono", count: 11},
    { label: "Galileo Figaro Yellow Shirt", count: 11},
    { label: "Gandhi's Open White Shirt", count: 11},
    { label: "Golden Boy Tank Top", count: 11},
    { label: "Gandhi's Leopard Shirt", count: 11},
    { label: "Great Soul Tie Dye Shirt 3", count: 11},
    { label: "Icon Leopard Shirt", count: 11},
    { label: "World Cup Pink Shirt", count: 11},
    { label: "Words into Wisdom Black Shirt", count: 11},
    { label: "Tie Dye Buttoned Up Shirt", count: 11},
    { label: "The X Ray Shirt", count: 11},
    { label: "The Small X Ray Shirt", count: 11},
    { label: "The Moon White Shirt", count: 11},
    { label: "The Moon Tie Dye Shirt", count: 11},
    { label: "Supreme Court Yellow Shirt", count: 11},
    { label: "Super Diva Black Shirt", count: 11},
    { label: "Simpsons Musk Black Shirt", count: 11},
    { label: "Sanskrit Colorful Shirt", count: 11},
    { label: "Keep Calm Orange Shirt", count: 11},
    { label: "NERD Buttoned Up Shirt", count: 11},  //31

    { label: "15 Minutes of Fame Shirt", count: 10},
    { label: "1863 Black Shirt", count: 10},
    { label: "A Night Out Jacket", count: 10},
    { label: "AC/DC Yellow Shirt", count: 10},
    { label: "Al's Biker Vest 1", count: 10},
    { label: "Al's Biker Vest 2", count: 10},
    { label: "Ali-Liston Black Shirt", count: 10},
    { label: "All is Relative Lime Tank Top", count: 10},
    { label: "Andy's Pop Shirt", count: 10},
    { label: "Andy's Room Vest", count: 10},
    { label: "Apple History Vest", count: 10},
    { label: "Arrest Report White Shirt", count: 10},
    { label: "Ask Not Black Tank Top", count: 10},
    { label: "Ask Not White Tank Top", count: 10},
    { label: "Atomicus White Shirt", count: 10},
    { label: "BLM Zebra Shirt", count: 10},
    { label: "Band Black & Red Shirt", count: 10},
    { label: "Bapu's Nice Shirt", count: 10},
    { label: "Basquiat Jacket", count: 10},
    { label: "Be The Change Teal Shirt", count: 10},  //51
    { label: "Becoming Black Tank Top", count: 10},
    { label: "Becoming Colorful Shirt 1", count: 10},
    { label: "Becoming Colorful Shirt 2", count: 10},
    { label: "Beef Steak Tie Dye Shirt 1", count: 10},
    { label: "Beef Steak Tie Dye Shirt 2", count: 10},
    { label: "Biker Vest & Wings Shirt", count: 10},
    { label: "Blood, Sweat & Tears White Shirt", count: 10},
    { label: "Bo Black Shirt", count: 10},
    { label: "Bo Cream Tank Top", count: 10},
    { label: "Boiled Egg White Shirt", count: 10},
    { label: "Book Club Wine Shirt", count: 10},
    { label: "Brainiac Button Up", count: 10},
    { label: "Butterfly & Bee Colorful Shirt", count: 10},
    { label: "Campbell's Screenprint White Shirt", count: 10},
    { label: "Cats <3 Red Collar Tank Top", count: 10},
    { label: "Change the World Light Blue Shirt", count: 10},
    { label: "Che Yellow Shirt", count: 10},
    { label: "Che/Warhol Leopard Shirt", count: 10},
    { label: "Chien Andalou Shirt", count: 10},
    { label: "Chupa White Shirt", count: 10},  //71
    { label: "Classic Denim Jacket", count: 10},
    { label: "Classy White Buttoned Up Shirt", count: 10},
    { label: "Clocks White Shirt", count: 10},
    { label: "Coat of Arms, Furry Jacket", count: 10},
    { label: "Collar Tank Top Leopard", count: 10},
    { label: "Corgis <3 Teal Shirt", count: 10},
    { label: "Created Equal Purple Shirt", count: 10},
    { label: "DREAM Red Shirt", count: 10},
    { label: "Dali QR Shirt", count: 10},
    { label: "Danger Pink Shirt", count: 10},
    { label: "Diana's Classic Denim Jacket", count: 10},
    { label: "Diego Colorful Shirt 2", count: 10},
    { label: "Diego Pocket Shirt", count: 10},
    { label: "Diego White & Yellow Shirt", count: 10},
    { label: "Don McLean Nineties Shirt", count: 10},
    { label: "Dont Do Drugs Tank Top", count: 10},
    { label: "Dont Obey Jacket", count: 10},
    { label: "Dont Stop Me Now Stars Shirt", count: 10},
    { label: "Doomsday Black Tank Top", count: 10},
    { label: "Drawer White Shirt", count: 10},
    { label: "Dream Leopard Shirt", count: 10},
    { label: "Dream Purple Shirt", count: 10},
    { label: "Dropout Yellow & Black Shirt", count: 10},
    { label: "Elon's Lightning Kimono", count: 10},
    { label: "Eternal Child Teal Shirt", count: 10},
    { label: "Everybody Knows White Tank Top", count: 10},
    { label: "Fail Striped Shirt", count: 10},
    { label: "Fingerprint Pink Shirt", count: 10},
    { label: "First We Take Manhattan Pink Shirt", count: 10},

]

let HAIR = [
    { label: "By Order Of Nikola Tesla", count: 70},
    { label: "Hard Day at The Office", count: 67},
    { label: "Ruth Means Business", count: 51},
    { label: "Classic RBG", count: 49},
    { label: "Classic Maradona", count: 47},
    { label: "All Out O", count: 41},
    { label: "Classic Gandhi", count: 40},
    { label: "Classic Marie Curie", count: 40},
    { label: "Join My Cult", count: 40},
    { label: "Welcome Home", count: 40},
    { label: "1940s Marie", count: 39},
    { label: "Classic Queen", count: 39},
    { label: "Golden God", count: 39},
    { label: "Living Rent Free in your Subconscious", count: 39},
    { label: "The X Ray Hat", count: 39},
    { label: "Big Daddy Kane", count: 38},
    { label: "Classic Einstein", count: 38},
    { label: "Classic Freddie", count: 38},
    { label: "Classic Rosa", count: 38},
    { label: "Doomsday Bert", count: 38}, //20

    { label: "Halle Parks", count: 38},
    { label: "Hat Man Jobs", count: 38},
    { label: "Im Into Jazz", count: 38},
    { label: "Madiba The Wise", count: 38},
    { label: "Mrs 2024", count: 38},
    { label: "Not Very Sane", count: 38},
    { label: "Rose Baby", count: 38},
    { label: "Same Old, Only Nicer", count: 38},
    { label: "Sanity Samurai", count: 38},
    { label: "Say My Name", count: 38},
    { label: "The Queen Let Loose", count: 38},
    { label: "Ultimate Frida", count: 38},  //32

    { label: "Chillmonger", count: 37},
    { label: "Fancy Frida Flowers", count: 37},
    { label: "Fight The Power", count: 37},
    { label: "Flowers of the Day", count: 37},
    { label: "Get With It", count: 37},
    { label: "Golden Boy of Politics", count: 37},
    { label: "Im Like Really Into Art", count: 37},
    { label: "King Freddie", count: 37},
    { label: "Lady Marie", count: 37},
    { label: "Madame Kahlo", count: 37},
    { label: "Raw Irish Sex Appeal", count: 37},
    { label: "Roll Up Her Sleeves", count: 37},
    { label: "Seventies Steve", count: 37},
    { label: "Street Salvador", count: 37},
    { label: "Talk Nerdy To Me", count: 37},
    { label: "The Amateur Bowler", count: 37},
    { label: "The CIA Disguise", count: 37},
    { label: "The Hot Conan", count: 37},
    { label: "The Ice Queen", count: 37},
    { label: "The Poet", count: 37},
    { label: "Time Bandit", count: 37},  //53

    { label: "70s Prom", count: 36},
    { label: "Abraham Luther King", count: 36},
    { label: "Classic Frida Flowers", count: 36},
    { label: "Classic Musk", count: 36},
    { label: "Dat Dude", count: 36},
    { label: "H.O.V.A", count: 36},
    { label: "Hippie Steve", count: 36},
    { label: "Incel Freud", count: 36},
    { label: "Lumberjack Chic", count: 36},
    { label: "Magician Galileo", count: 36},
    { label: "Mr. Farenheit", count: 36},
    { label: "Party Hard", count: 36},
    { label: "The Elite", count: 36},
    { label: "The Good Hat", count: 36},
    { label: "The Proud Man", count: 36},
    { label: "The intellectual", count: 36},
    { label: "Theater Night", count: 36},
    { label: "Thembu Power", count: 36},
    { label: "Tough Guy King", count: 36},
    { label: "Uncle Al", count: 36},
    { label: "Wild Princess", count: 36}, //74

    { label: "Barry", count: 35},
    { label: "Bling Baby", count: 35},
    { label: "Classic Freud", count: 35},
    { label: "Do The Right Thing", count: 35},
    { label: "Down To Earth", count: 35},
    { label: "Dreadhead Rosa", count: 35},
    { label: "Frida's Room Bow", count: 35},
    { label: "East Coast Girl", count: 35},
    { label: "Future Man", count: 35},
    { label: "Hydra(ted)", count: 35},
    { label: "Lady D", count: 35},
    { label: "Miami Private Eye", count: 35},
    { label: "Mi Scusi!", count: 35},
    { label: "Mr.Warhola", count: 35},
    { label: "Rebel With a Cause", count: 35},
    { label: "Rich White Man", count: 35},
    { label: "Ride The Electric Train", count: 35},
    { label: "Skater Al", count: 35},
    { label: "The Arms Dealer", count: 35},
    { label: "The Bowler", count: 35},
    { label: "The Boy Band", count: 35},
    { label: "The Che", count: 35},
    { label: "The Sinner", count: 35},
    { label: "The New Wave", count: 35},
    { label: "Verbal Ninja", count: 35},

    { label: "A Nice Day Outside", count: 34},
]

let TATTOOS = [
    { label: "Van Gogh Tattoos 2", count: 127},
    { label: "Leonard Cohen Tattoos 2", count: 118},
    { label: "Freud Tattoos 3", count: 113},
    { label: "Steve Jobs Tattoos 1", count: 110},
    { label: "Van Gogh Tattoos 3", count: 104},
    { label: "Mandela Tattoos 3", count: 103},
    { label: "Freud Tattoos 2", count: 99},
    { label: "Michelle Tattoos 5", count: 99},
    { label: "Freud Tattoos 1", count: 98},
    { label: "Einstein Tattoos 4", count: 96},
    { label: "Lincoln Tattoos 4", count: 94},
    { label: "Galileo Tattoos 4", count: 93},
    { label: "Barack Obama Tattoos 4", count: 92},
    { label: "Mandela Tattoos 1", count: 92},
    { label: "Gandhi Tattoos 3", count: 90},
    { label: "Van Gogh Tattoos 1", count: 90},
    { label: "Barack Obama Tattoos 5", count: 89},
    { label: "Princess Diana Tattoos 5", count: 89},
    { label: "Maradona Tattoos 2", count: 88},
    { label: "MLK Tattoos 1", count: 87},  //20
    { label: "RBG Tattoos 4", count: 86},
    { label: "Andy Warhol Tattoos 2", count: 84},
    { label: "Dali Tattoos 5", count: 84},
    { label: "Rosa Parks Tattoos 2", count: 83},
    { label: "Ali Tattoos 3", count: 82},
    { label: "Leonard Cohen Tattoos 1", count: 81},
    { label: "Lincoln Tattoos 5", count: 81},
    { label: "Marie Curie Tattoos 1", count: 81},
    { label: "Oprah Tattoos 1", count: 81},
    { label: "MLK Tattoos 4", count: 80},
    { label: "Marie Curie Tattoos 3", count: 80},
    { label: "Marilyn Tattoos 1", count: 80},
    { label: "Rosa Parks Tattoos 3", count: 80},
    { label: "Tesla Tattoos 1", count: 80},
    { label: "Oprah Tattoos 5", count: 79},
    { label: "MLK Tattoos 3", count: 78},
    { label: "Marilyn Tattoos 2", count: 78},
    { label: "Andy Warhol Tattoos 1", count: 77},
    { label: "Freddie Tattoos 4", count: 77},
    { label: "Steve Jobs Tattoos 2", count: 77},  //40

    { label: "JFK Tattoos 2", count: 76},
    { label: "Maradona Tattoos 1", count: 76},
    { label: "Rosa Parks Tattoos 1", count: 76},
    { label: "Elon Musk Tattoos 1", count: 74},
    { label: "Freddie Tattoos 3", count: 74},
    { label: "Ali Tattoos 4", count: 73},
    { label: "Churchill Tattoos 2", count: 73},
    { label: "JFK Tattoos 5", count: 73},
    { label: "Princess Diana Tattoos 4", count: 73},
    { label: "Dali Tattoos 2", count: 72},
    { label: "Elon Musk Tattoos 4", count: 72},
    { label: "Frida Tattoos 2", count: 72},
    { label: "Freddie Tattoos 1", count: 71},
    { label: "Frida Tattoos 3", count: 71},
    { label: "Picasso Tattoos 5", count: 71},
    { label: "Marilyn Tattoos 3", count: 70},
    { label: "Picasso Tattoos 4", count: 69},
    { label: "Marie Curie Tattoos 4", count: 68},
    { label: "Ali Tattoos 2", count: 67},
    { label: "Gandhi Tattoos 2", count: 67},  //60

    { label: "Tesla Tattoos 4", count: 67},
    { label: "The Queen Tattoos 4", count: 67},
    { label: "Churchill Tattoos 3", count: 66},
    { label: "Elon Musk Tattoos 3", count: 66},
    { label: "Freddie Tattoos 2", count: 66},
    { label: "Galileo Tattoos 2", count: 66},
    { label: "Andy Warhol Tattoos 3", count: 65},
    { label: "Churchill Tattoos 4", count: 65},
    { label: "Churchill Tattoos 5", count: 65},
    { label: "Oprah Tattoos 3", count: 65},
    { label: "Tesla Tattoos 2", count: 65},
    { label: "The Queen Tattoos 2", count: 64},
    { label: "Einstein Tattoos 5", count: 62},
    { label: "JFK Tattoos 1", count: 62},
    { label: "Maradona Tattoos 4", count: 62},
    { label: "Picasso Tattoos 1", count: 62},
    { label: "Tesla Tattoos 3", count: 62},
    { label: "Andy Warhol Tattoos 4", count: 61},
    { label: "Ali Tattoos 1", count: 60},
    { label: "Barack Obama Tattoos 1", count: 60},  //80

    { label: "Elon Musk Tattoos 2", count: 60},
    { label: "Rosa Parks Tattoos 4", count: 60},
    { label: "The Queen Tattoos 1", count: 60},
    { label: "The Queen Tattoos 3", count: 60},
    { label: "Dali Tattoos 3", count: 59},
    { label: "RBG Tattoos 5", count: 59},
    { label: "Gandhi Tattoos 1", count: 58},
    { label: "Michelle Tattoos 2", count: 58},
    { label: "Frida Tattoos 1", count: 57},
    { label: "Picasso Tattoos 2", count: 57},
    { label: "Frida Tattoos 4", count: 56},
    { label: "Galileo Tattoos 5", count: 56},
    { label: "Maradona Tattoos 3", count: 54},
    { label: "RBG Tattoos 2", count: 54},
    { label: "Churchill Tattoos 1", count: 53},
    { label: "Einstein Tattoos 2", count: 53},
    { label: "Marilyn Tattoos 5", count: 53},
    { label: "RBG Tattoos 3", count: 53},
    { label: "Steve Jobs Tattoos 3", count: 53},
    { label: "Dali Tattoos 4", count: 52},
]

module.exports = {
    ACCESSORIES,
    FOURTH_CLUB,
    THIRD_CLUB,
    SECOND_CLUB,
    CLUB,
    EAR,
    SPECIAL_CLUB_ITEM,
    SPECIAL_VIBES,
    SPECIAL_EDITION,
    SPECIAL_LEGEND_ITEM,
    LEGEND,
    VIBES,
    BACKGROUND,
    CLOTHES,
    HAIR,
    TATTOOS,
    twitter: 'https://twitter.com/TheLegendsNFTs',
    instagram: 'https://www.instagram.com/houseoflegends.nft/',
    discord: 'http://discord.gg/jkbjQqPzjD',
    email: 'http://discord.gg/jkbjQqPzjD',
    // STAKING_CONTRACT_ADDRESS: '0xFB4c3EA4837A84D47fB0857973C43D1b8d4671f9',  //Testnet
    STAKING_CONTRACT_ADDRESS: '0xf15D34852AcFfd15b4e4D54aE2eA7031642D0d21',  // Mainnet
    STAKING_CONTRACT_ABI: [
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC721Received",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "name": "stake",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256[]",
                    "name": "_tokenIds",
                    "type": "uint256[]"
                }
            ],
            "name": "stakeBatch",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "contract ERC721Enumerable",
                    "name": "nftContractAddress",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256[]",
                    "name": "tokenIds",
                    "type": "uint256[]"
                }
            ],
            "name": "Staked",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "name": "unstake",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256[]",
                    "name": "_tokenIds",
                    "type": "uint256[]"
                }
            ],
            "name": "unstakeBatch",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256[]",
                    "name": "tokenIds",
                    "type": "uint256[]"
                }
            ],
            "name": "Unstaked",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_user",
                    "type": "address"
                }
            ],
            "name": "getStakedTokens",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "tokenIds",
                    "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "tokenOwner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ],
    // NFT_CONTRACT_ADDRESS: '0x0D731c7D2247d53a22cE8848F62908991883CF0B',   //Testnet
    NFT_CONTRACT_ADDRESS: '0x8C714199d2eA08CC1f1F39A60f5cD02aD260A1e3',   //Mainnet    
    NFT_CONTRACT_ABI: [
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "name_",
                    "type": "string"
                },
                {
                    "internalType": "string",
                    "name": "symbol_",
                    "type": "string"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "approved",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "Approval",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "bool",
                    "name": "approved",
                    "type": "bool"
                }
            ],
            "name": "ApprovalForAll",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "Transfer",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getApproved",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                }
            ],
            "name": "isApprovedForAll",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "ownerOf",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"
                },
                {
                    "internalType": "bool",
                    "name": "approved",
                    "type": "bool"
                }
            ],
            "name": "setApprovalForAll",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes4",
                    "name": "interfaceId",
                    "type": "bytes4"
                }
            ],
            "name": "supportsInterface",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "tokenURI",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "transferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}