import { useMoralisWeb3Api, useMoralis } from "react-moralis";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import cross from '../../assets/cross.svg';
import cross1 from '../../assets/cross-circle.svg';
import tick from '../../assets/tick-green.svg';



const TempNFT = () => {
  const [nfts, setNfts] = useState<any>([]);
  const Web3Api = useMoralisWeb3Api();
  const { isInitialized } = useMoralis();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleOpen1 = () => setShow1(true);


  useEffect(() => {
    // const fetchNFTsForContract = async () => {
    //     console.log('Fetching');
    //     setNfts(await Web3Api.token.getAllTokenIds({
    //         chain: "eth",
    //         offset: 500,
    //         address: "0x8C714199d2eA08CC1f1F39A60f5cD02aD260A1e3",
    //     }))
    // }
    // if (isInitialized) {
    //     fetchNFTsForContract();
    // }

  }, [isInitialized])
  return (
    <>
      All NFTs
      <button onClick={() => handleOpen()}>Open Modal</button>
      <button onClick={() => handleOpen1()}>Open Modal2</button>
      <Modal
        show={show}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={tick} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">Perk Added To Cart</div>
          <div><a onClick={() => handleClose()} className="btn connectBtn d-flex">Okay</a></div>
        </div>
      </Modal>

      <Modal
        show={show1}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose1}
        dialogClassName="customModal"
      >

        <div className="modal-body">
          <div className="icon">
            <img src={cross1} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">Not Enough Gems to complete <br />
            the purchase !</div>
          <div><a onClick={() => handleClose1()} className="btn connectBtn d-flex">Okay</a></div>
        </div>
      </Modal>
    </>
  )
}

export default TempNFT