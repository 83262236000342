import { useEffect, useState } from 'react'
import { Question } from './Question'


const Faq = ({ setFlag }) => {

    const _data = [
        {

            title: "How do I pay for the Essentials or Premium plan?",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        },
        {
            title: "Aliquam mauris enim nisl eu.",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        }, {
            title: "Feugiat blandit egestas lectus morbi.",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        }, {
            title: "Ornare eros, porta fringilla mattis.",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        }, {
            title: "Nunc eget nibh ac imperdiet egestas.",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        }, {
            title: "Vitae, pellentesque congue maecenas ut quisque euismod.",
            question: "Sit consectetur morbi facilisi diam. Eget duis fringilla eget morbi porttitor leo et. Mauris turpis a erat sit nunc in ac ac. Ut quis eget diam suspendisse. Bibendum pharetra enim netus fames ultricies non orci. Vel elit aliquam netus netus vitae consequat, aliquet donec. Varius commodo faucibus odio nibh auctor volutpat et. Nisi sem sagittis, adipiscing id laoreet facilisi nullam blandit. Consequat lobortis ac risus eu ut neque, felis, velit. Molestie tortor morbi dignissim a, nunc mauris duis gravida pharetra. Vel nibh turpis quis augue."
        },
    ]
 

    useEffect(() => {
        setFlag(7)
    }, [])
 


    return (
        <>
            <div className="faq">
                <div className="container">
                    <div className="fs-36 fw-700 text-white before mb-80">Freequently Asked Questions</div>
                    <div className="faqCard">
                        <div className="faqTabs">
                            <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="Buying-tab" data-toggle="tab" href="#Buying" role="tab" aria-controls="Buying" aria-selected="true">Buying</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="Selling-tab" data-toggle="tab" href="#Selling" role="tab" aria-controls="Selling" aria-selected="false">Selling</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="Overview-tab" data-toggle="tab" href="#Overview" role="tab" aria-controls="Overview" aria-selected="false">Overview</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="Game-tab" data-toggle="tab" href="#Game" role="tab" aria-controls="Game" aria-selected="false">Game</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="Collectibles-tab" data-toggle="tab" href="#Collectibles" role="tab" aria-controls="Collectibles" aria-selected="false">Collectibles</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="Staking-tab" data-toggle="tab" href="#Staking" role="tab" aria-controls="Staking" aria-selected="false">Staking</a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane active" id="Buying" role="tabpanel" aria-labelledby="Buying-tab">

                                    <div className="row mx-0 justify-content-center">
                                        <div className="col-lg-8" >{_data.map((question) => (
                                            <Question question={question} />
                                          
                                        ))}
                                        </div>
                                    </div>

                                </div>
                                <div className="tab-pane" id="Selling" role="tabpanel" aria-labelledby="Selling-tab">
                                    <h1>Selling-tab</h1>
                                </div>
                                <div className="tab-pane" id="Overview" role="tabpanel" aria-labelledby="Overview-tab">
                                    <h1>Overview-tab</h1>
                                </div>
                                <div className="tab-pane" id="Game" role="tabpanel" aria-labelledby="Game-tab">
                                    <h1>Game-tab</h1>
                                </div>
                                <div className="tab-pane" id="Collectibles" role="tabpanel" aria-labelledby="Collectibles-tab">
                                    <h1>Collectibles-tab</h1>
                                </div>
                                <div className="tab-pane" id="Staking" role="tabpanel" aria-labelledby="Staking-tab">
                                    <h1>Staking-tab</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq
