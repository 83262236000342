import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import './nft-checkout.css';
import { Modal } from "react-bootstrap";
import cross from '../../assets/cross.svg';
import Web3 from 'web3';
import cross1 from '../../assets/cross-circle.svg';
import moment from 'moment';
import InputRange from "react-input-range";
import links from '../../config/constants';
let api_url = process.env.REACT_APP_BACKEND_URL;
let web3: Web3 | undefined = undefined;
let STAKING_CONTRACT_ADDRESS: any = links.STAKING_CONTRACT_ADDRESS;
let STAKING_CONTRACT_ABI: any = links.STAKING_CONTRACT_ABI;

const NftCheckout = ({ setFlag, userAddress }) => {
    const [range, setRange] = useState({ min: 0, max: 100 });
    const [userUnstakedNFTs, setUserNFTs] = useState<any>([]);
    const [userFilterNFTs, setUserFilterNFTs] = useState<any>([]);
    const [deductArray, setDeductArray] = useState<any>([]);
    const [total, setTotal] = useState(0);
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [errorText, setErrorText] = useState('');
    // const [availableGems, setAvailableGems] = useState(0);
    // const [itemsArray, setItemsArray] = useState<any>([]);
    // const [itemsArrayMobile, setItemsArrayMobile] = useState<any>([]);
    const [newRewardGems, setNewRewardGems] = useState(0);


    const initializeContract = async (abi, address) => {
        const web3 = new Web3(Web3.givenProvider)
        let contract = await new web3.eth.Contract(abi, address)
        return contract;
    }


    const getUserNFTs = async (address) => {
        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        let stakedAssets = await contract_instance.methods.getStakedTokens(address).call();

        fetch(`${api_url}/staking/filter-nfts`, {
            body: JSON.stringify({ stakedAssets }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
            method: 'POST',
        })
        .then((response) => response.json())
        .then(async(response) => {
            let nftArray = response.data.unstakedNFTs.concat(response.data.stakedNFTs);
            setRange({min:0, max:nftArray.sort( (a, b) => {
                return b.noOfGems - a.noOfGems;
            })[0]?.noOfGems});
            setUserNFTs(response.data.unstakedNFTs.concat(response.data.stakedNFTs));
            setUserFilterNFTs(response.data.unstakedNFTs.concat(response.data.stakedNFTs));
            setDeductArray(Array(response.data.unstakedNFTs.length + response.data.stakedNFTs.length).fill(0));
            // let itemsProcessed = 0;
            // response.data.stakedNFTs.forEach((nft, index, array) => {
            //     let now = moment(new Date());
            //     let end = moment(nft?.startDate);
            //     let duration = moment.duration(now.diff(end));
            //     let days = duration.asDays();
            //     days = Number(days.toFixed(0));
            //     let gems = 0;
            //     if (days < 30) {
            //         gems = 10 * days;
            //     } else if (days > 30 && days <= 90) {
            //         gems = 10 * 30;
            //         gems += 20 * (days - 30);
            //     } else if (days > 90) {
            //         gems = 10 * 30;
            //         gems += 20 * 60;
            //         gems += 30 * (days - 90);
            //     }

            //     let tempAllNFTs = allNFTs.filter(ele => ele.tokenId == nft.asset);
            //     nftArray.push({
            //         noOfGems: gems + (tempAllNFTs[0]?.noOfGems || 0),
            //         tokenId: nft.asset
            //     })
            //     itemsProcessed++;
            //     if (itemsProcessed == array.length) {
            //         setRange({min:0, max:nftArray.sort( (a, b) => {
            //             return b.noOfGems - a.noOfGems;
            //         })[0]?.noOfGems});
            //         setUserNFTs(nftArray);
            //         setUserFilterNFTs(nftArray);
            //         setDeductArray(Array(response.data.unstakedNFTs.length + response.data.stakedNFTs.length).fill(0));
            //     }
            // })
        });
    }

    const getCartItems = () => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        let total = 0;
        for (let i = 0; i < cartItems?.length; i++) {
            total += cartItems[i].price * Number(cartItems[i].quantity);
        }
        setTotal(total);
    }

    const onCheckout = () => {
        if (total > (availableGems + newRewardGems)) {
            setErrorText('Low Gems Balance!');
            handleOpen();
        } else if (total == 0) {
            setErrorText('No Item in the cart!');
            handleOpen();
        } else {
            let flag = 0;
            for (let i = 0; i < deductArray.length; i++) {
                if (deductArray[i] > userUnstakedNFTs[i]?.noOfGems) {
                    flag = 1;
                    setErrorText('You have entered more gems than you have for one or more NFTs!');
                    handleOpen();
                    break;
                }
            }
            if (flag == 0) {
                let sum = 0;
                for (let i = 0; i < deductArray.length; i++) {
                    sum += Number(deductArray[i])
                }

                if (sum > total) {
                    setErrorText('You have entered more gems than required!');
                    handleOpen();
                } else if (sum < total) {
                    if(sum + newRewardGems < total){
                        setErrorText('You have entered less gems than required!');
                        handleOpen();
                    }else{
                        let claimDeduction = total - sum;
                        localStorage.setItem('claimDeduction', claimDeduction.toString());
                        let deduction = [];
                        let itemsProcessed = 0;
                        deductArray.forEach((amount, index, array) => {
                            if (amount != 0) {
                                deduction.push({ amount: amount, asset: userUnstakedNFTs[index].tokenId })
                            }
                            itemsProcessed++;
                            if (itemsProcessed == array.length) {
                                localStorage.setItem('deduction', JSON.stringify(deduction));
                                navigate("/checkout", { replace: true });
                            }
                        })
                    }
                    
                } else {
                    let deduction = [];
                    let itemsProcessed = 0;
                    deductArray.forEach((amount, index, array) => {
                        if (amount != 0) {
                            deduction.push({ amount: amount, asset: userUnstakedNFTs[index].tokenId })
                        }
                        itemsProcessed++;
                        if (itemsProcessed == array.length) {
                            localStorage.setItem('deduction', JSON.stringify(deduction));
                            navigate("/checkout", { replace: true });
                        }
                    })
                }
            }

        }
    }

    const onDeductChange = (event, index) => {
        let tempArray = deductArray;
        tempArray[index] = event.target.value;
        setDeductArray(tempArray);
    }

    const handleApplyFilter = () => {
        setUserNFTs(
            userFilterNFTs.filter(ele => ele.noOfGems >= range.min && ele.noOfGems <= range.max)
        )
    }

    const getRewardGems = async () => {
        fetch(`${api_url}/claim/record`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
        })
        .then((response) => response.json())
        .then((response) => response.data.record.gems - response.data.record.usedGems)
        .then((response) => setNewRewardGems(response))

    }

    let availableGems = 0;
    let itemsArray = [];
    let itemsArrayMobile = [];
    for (let i = 0; i < userUnstakedNFTs?.length; i++) {
        availableGems += userUnstakedNFTs[i]?.noOfGems;
        itemsArray.push(
            <div className="inner-card mb-30" key={i}>
                <div className="row align-items-center mx-0">
                    <div className="col-lg-5 px-0">
                        <div className="d-flex align-items-center">
                            <div className="wishlist-img">
                                <img src={"https://legendary-vault.s3.us-west-1.amazonaws.com/images/nfts/" + userUnstakedNFTs[i]?.tokenId + ".png"} alt="" />
                            </div>
                            <div className="wishlist-description"># {userUnstakedNFTs[i]?.tokenId}</div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center px-0"><div className="fs-25 fw-700 text-white">{userUnstakedNFTs[i]?.noOfGems}</div></div>
                    <div className="col-lg-3 text-right px-0">
                        <div className="inner-input">
                            <input type="number" placeholder="100" min={0} max={userUnstakedNFTs[i]?.noOfGems} onChange={(e) => onDeductChange(e, i)} />
                        </div>
                    </div>
                </div>
            </div>
        )
        itemsArrayMobile.push(
            <div className="inner-card mb-30" key={i}>
                <div className="d-flex align-items-center mb-30">
                    <div className="col-4 px-0">
                        <div className="nft-img">
                            <img src={"https://ipfs.io/ipfs/QmcNmNc3sEPg2HgFGR9mTvuoivzHyVQeL9mLaQBHy1oPZS/" + userUnstakedNFTs[i]?.tokenId + ".png"} alt="" />
                        </div>
                    </div>
                    <div className="col-7 px-0">
                        <div className="fs-14 fw-400 text-white lineClamp"># {userUnstakedNFTs[i]?.tokenId}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-30">
                    <div className="fs-12 fw-500 text-white">Total Gems</div>
                    <div className="fs-16 fw-700 text-white">{userUnstakedNFTs[i]?.noOfGems}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="fs-12 fw-500 text-white">Deduct</div>
                    <div className="inputBox"><input type="number" min={0} max={userUnstakedNFTs[i]?.noOfGems} onChange={(e) => onDeductChange(e, i)} /></div>
                </div>
            </div>
        )
    }



    useEffect(() => {
        try {
            if (userAddress) {
                getUserNFTs(userAddress);
                getRewardGems();
                
            } else {
                setUserNFTs([]);
                setUserFilterNFTs([]);
            }
        } catch (e) { }
        
        localStorage.removeItem('deduction');
        getCartItems();
        setFlag(6);
    }, [userAddress])

    return (
        <>
            <div className="ntf-Wishlist">
                <div className="container d-none d-lg-block">
                    <div className="row mx-0 justify-content-between before mb-70 align-items-center">
                        <div className="fs-30 fw-700 text-white mb-4 mb-lg-0">{total} Legendary Gems required</div>
                        <button className="btn legendryBtn-green ">
                            {userAddress && <span className="innerText">You Have {availableGems + newRewardGems} Legendary Gems</span>}
                            {!userAddress && <span className="innerText">Connect Wallet to see Earned Legendary Gems</span>}
                        </button>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-4">
                            <div className="leftSidebar wishlist-card px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="fs-25 fw-700 text-white">Number of Gems</div>
                                    <div className="fs-18 fw-400 text-white pointer">Clear all</div>
                                </div>

                                <div className="inputRange">
                                    <InputRange
                                        maxValue={userFilterNFTs.sort( (a, b) => {
                                            return b?.noOfGems - a?.noOfGems;
                                        })[0]?.noOfGems}
                                        minValue={0}
                                        value={range}
                                        onChange={(value: any) => { setRange(value); }} />
                                </div>

                                <div className="mt-40"><a className="ApplyBtn" onClick={() =>handleApplyFilter()}>Apply Filter</a></div>

                            </div>
                        </div>

                        <div className="col-lg-8">

                            <div className="wishlist-card">
                                <div className="row mx-0 mb-30">
                                    <div className="col-lg-5 px-0"><div className="fs-12 fw-500 text-white text-center">Item</div></div>
                                    <div className="col-lg-4 px-0"><div className="fs-12 fw-500 text-white text-center">Total Gems</div></div>
                                    <div className="col-lg-3 px-0"><div className="fs-12 fw-500 text-white">Deduct</div></div>
                                </div>
                                {itemsArray}
                                <div className="row mx-0 justify-content-end">
                                    <div className="col-lg-4">
                                        <Link to='/checkout'>
                                            <a className="btn connectBtn w-100">Auto Deduct Gems</a>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="btn connectBtn w-100" onClick={() => onCheckout()}>Checkout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container d-block d-lg-none">
                    <div className="row mx-0">
                        <div className="col-md-4 mb-4 mb-lg-0">
                            <div className="leftSidebar wishlist-card px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="fs-25 fw-700 text-white">Number of Gems</div>
                                    <div className="fs-18 fw-400 text-white pointer">Clear all</div>
                                </div>

                                <div className="inputRange">
                                    <InputRange
                                        maxValue={userUnstakedNFTs.sort( (a, b) => {
                                            return b?.noOfGems - a?.noOfGems;
                                        })[0]?.noOfGems}
                                        minValue={0}
                                        value={range}
                                        onChange={(value: any) => { setRange(value); }} />
                                </div>

                                <div className="mt-40"><a className="ApplyBtn" onClick={() =>handleApplyFilter()}>Apply Filter</a></div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="mobile-cards">
                                {itemsArrayMobile}
                                <div className="row mx-0 justify-content-end">
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                        <a className="btn connectBtn w-100" onClick={() => onCheckout()}>Checkout</a>
                                    </div>
                                    <div className="col-lg-4">
                                        {/* <Link to='/checkout'> */}
                                            <a className="btn connectBtn w-100">Auto Deduct Gems</a>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={show}
                    style={{ opacity: 1 }}
                    animation={false}
                    onHide={handleClose}
                    dialogClassName="customModal"
                >
                    <div className="modal-body">
                    <div className="icon">
                        <img src={cross1} alt="" />
                    </div>
                    <div className="fs-18 fw-700 text-white text-center my-4">{errorText}</div>
                    <div><a onClick={() => handleClose()} className="btn connectBtn d-flex">Okay</a></div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default NftCheckout