import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import cross from '../../assets/cross.svg';
import tick from '../../assets/tick-green.svg';
import Web3 from 'web3';
import links from '../../config/constants';
import cross1 from '../../assets/cross-circle.svg';
let api_url = process.env.REACT_APP_BACKEND_URL;
let web3: Web3 | undefined = undefined;
let NFT_CONTRACT_ADDRESS: any = links.NFT_CONTRACT_ADDRESS;
let NFT_CONTRACT_ABI: any = links.NFT_CONTRACT_ABI;
let STAKING_CONTRACT_ADDRESS: any = links.STAKING_CONTRACT_ADDRESS;
let STAKING_CONTRACT_ABI: any = links.STAKING_CONTRACT_ABI;

const Stake = ({ setFlag, userAddress, rewardGems, setUserAddress, setCartItemsCount}) => {
    const [userUnstakedNFTs, setUserUnstakedNFTs] = useState<any>([]);
    const [userStakedNFTs, setUserStakedNFTs] = useState<any>([]);
    const [allNFTs, setAllNFTs] = useState<any>([]);
    const [allPerks, setAllPerks] = useState<any>([]);
    const [selectedPerk, setSelectedPerk] = useState<any>({});
    const [quantity, setQuantity] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => { setQuantity(1); setErrorText(''); setShow(false) };
    const handleOpen = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleOpen1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleOpen2 = () => setShow2(true);
    const [newRewardGems, setNewRewardGems] = useState(0);
    const [errorText, setErrorText] = useState('');
    const [showApproveAll, setShowApproveAll] = useState(true);


    let topItems = [];
    for (let i = 0; i < allPerks?.length; i++) {
        topItems.push(
            <div className="gemCard" key={i}>
                <div className="gem-img">
                    <img src={allPerks[i]?.image} alt="" />
                </div>
                <div className="legendBadge">{allPerks[i]?.description}</div>
                <div className="gemBody">
                    <div className="d-flex  justify-content-between">
                        <div className="d-flex flex-column">
                            <div className="price">$GEMS</div>
                            <div className="fs-25 fw-700 text-white">{allPerks[i]?.price} </div>
                        </div>
                        <div className=""><button className='gradientBtn-outline' onClick={() => handleBuyClick(allPerks[i])}>Buy</button></div>
                    </div>
                    <div className="footer-card">
                        <div>Sold: <span className='fs-25'>{allPerks[i]?.sold}</span></div>
                        <div>Available: <span className='fs-25'>{allPerks[i]?.left}</span></div>
                    </div>
                </div>
            </div>
        )
    }

    const getAllPerks = () => {
        fetch(`${api_url}/perk`)
            .then(response => response.json())
            .then(response => {
                let res = response.data.perks.docs;
                res = res.filter(perk => perk.showOnTop == true)
                setAllPerks(res);
            })
    }

    const initializeContract = async (abi, address) => {
        const web3 = new Web3(Web3.givenProvider)
        let contract = await new web3.eth.Contract(abi, address)
        return contract;
    }

    const checkIsApprovedForAll = async () => {
        const contract_instance = await initializeContract(NFT_CONTRACT_ABI, NFT_CONTRACT_ADDRESS);
        let response = await contract_instance.methods.isApprovedForAll(
            userAddress,  //0xF354582e017B363254550e8e06A558596920eb20
            STAKING_CONTRACT_ADDRESS
        ).call();
        // console.log('Approved: ', response);
        setShowApproveAll(response);
        // setShowApproveAll(false);
    }

    const approveAll = async () => {
        const contract_instance = await initializeContract(NFT_CONTRACT_ABI, NFT_CONTRACT_ADDRESS);
        contract_instance.methods.setApprovalForAll(
            STAKING_CONTRACT_ADDRESS,
            true
        ).send({
            from: userAddress
        })
        .then((response) => setShowApproveAll(true))
    }

    const stake = async (asset) => {
        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        contract_instance.methods.stake(asset?.tokenId).send({
            from: userAddress
        }).then((stakingResponse) => {
            let temp = userStakedNFTs;
            temp.push(asset);
            setUserStakedNFTs([...temp]);

            let temp2 = userUnstakedNFTs;
            let index = temp2.map(x => x.tokenId).indexOf(asset.tokenId);
            temp2.splice(index, 1);
            setUserUnstakedNFTs([...temp2]);
            // fetch(`${api_url}/staking`, {
            //     body: JSON.stringify({ asset }),
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Token ${localStorage.getItem('gemToken')}`
            //     },
            //     method: 'POST',
            // })
            // .then((response) => response.json())
            // .then((response) => getUserNFTs(userAddress))
        })
    }

    const unstake = async (asset) => {
        let contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        contract_instance.methods.unstake(asset.tokenId).send({
            from: userAddress
        }).then((unstakingResponse) => {
            let temp = userUnstakedNFTs;
            temp.push(asset);
            setUserUnstakedNFTs([...temp]);

            let temp2 = userStakedNFTs;
            let index = temp2.map(x => x.tokenId).indexOf(asset.tokenId);
            temp2.splice(index, 1);
            setUserStakedNFTs([...temp2]);
            // fetch(`${api_url}/staking/unstake`, {
            //     body: JSON.stringify({ asset }),
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Token ${localStorage.getItem('gemToken')}`
            //     },
            //     method: 'POST',
            // })
            // .then((response) => response.json())
            // .then((response) => getUserNFTs(userAddress));
        })
    }

    const stakeAll = async() => {
        let assetsToStake = userUnstakedNFTs.map((nft) => {
            return nft.tokenId;
        });
        let assetsArray = assetsToStake.map(x => Number(x));
        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        contract_instance.methods.stakeBatch(assetsArray).send({
            from: userAddress
        }).then((stakingResponse) => {
            let temp = userStakedNFTs.concat(userUnstakedNFTs);
            setUserStakedNFTs([...temp]);

            setUserUnstakedNFTs([...[]]);
            // fetch(`${api_url}/staking/stakeAll`, {
            //     body: JSON.stringify({ assetsToStake }),
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Token ${localStorage.getItem('gemToken')}`
            //     },
            //     method: 'POST',
            // })     
            // .then((response) => response.json())
            // .then((response) => getUserNFTs(userAddress))  
        })
    }

    const handleBuyClick = (perk) => {
        if (!userAddress) {
            alert('You need to connect your wallet to buy a perk!')
        } else {
            setSelectedPerk(perk);
            handleOpen()
        }
    }


    let heldUnstakedNFTs = [];
    let heldStakedNFTs = [];
    let totalGems = 0;
    let legendaryGemsCollected = 0;
    let potentialLegendaryGems = 1500 * userUnstakedNFTs?.length;

    for (let i = 0; i < userUnstakedNFTs?.length; i++) {
        heldUnstakedNFTs.push(
            <div className="stake-innerCard mb-30" key={i}>
                <div className="row align-items-center mx-0">
                    <div className="col-lg-4 px-0">
                        <div className="d-flex">
                            <div className="nftImg mb-20 mb-lg-0">
                                <img src={"https://legendary-vault.s3.us-west-1.amazonaws.com/images/nfts/" + userUnstakedNFTs[i]?.tokenId + ".png"} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 px-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="fs-18 font-weight-bold text-white">#{userUnstakedNFTs[i]?.tokenId}</div>
                            <div className="fs-18 fw-400 d-flex text-white">{userUnstakedNFTs[i]?.noOfGems} $GEMS</div>
                            <div className="ml-504">
                                <button className="btn stakeBtn" onClick={() => stake(userUnstakedNFTs[i])} disabled={!showApproveAll}>
                                    <span className="stakeBtn-inner">Stake</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    for (let i = 0; i < userStakedNFTs?.length; i++) {
        // let now = moment(new Date());
        // let end = moment(userStakedNFTs[i]?.startDate);
        // let duration = moment.duration(now.diff(end));
        // let days = duration.asDays();
        // days = Number(days.toFixed(0));
        // let gems = 0;
        // if (days < 30) {
        //     gems = 10 * days;
        // } else if (days > 30 && days <= 90) {
        //     gems = 10 * 30;
        //     gems += 20 * (days - 30);
        // } else if (days > 90) {
        //     gems = 10 * 30;
        //     gems += 20 * 60;
        //     gems += 30 * (days - 90);
        // }
        // legendaryGemsCollected += gems;
        // totalGems += gems;
        heldStakedNFTs.push(
            <div className="stake-innerCard mb-30" key={i}>
                <div className="row align-items-center mx-0">
                    <div className="col-lg-6 px-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="nftImg mb-20 mb-lg-0">
                                <img src={"https://legendary-vault.s3.us-west-1.amazonaws.com/images/nfts/" + userStakedNFTs[i]?.tokenId + ".png"} alt="" />
                            </div>
                            <div className="fs-18 font-weight-bold text-white d-none d-lg-block mr-5"># {userStakedNFTs[i]?.tokenId}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 px-0">
                        <div className="d-flex justify-content-lg-end justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                                <div className="fs-12 fw-700 text-white d-block d-lg-none mb-3"># {userStakedNFTs[i]?.tokenId}</div>
                                <div className="fs-18 fw-400 text-white">{userStakedNFTs[i]?.noOfGems} $GEMS</div>
                            </div>
                            <div className="ml-50">
                                <button className="btn stakeBtn unstakeBtn">
                                    <span className="stakeBtn-inner" onClick={() => unstake(userStakedNFTs[i])}>Unstake</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    for (let i = 0; i < allNFTs?.length; i++) {
        totalGems += allNFTs[i].noOfGems;
    }

    const getUserNFTs = async (address) => {
        const contract_instance = await initializeContract(STAKING_CONTRACT_ABI, STAKING_CONTRACT_ADDRESS);
        let stakedAssets = await contract_instance.methods.getStakedTokens(address).call();
        fetch(`${api_url}/staking/filter-nfts`, {
            body: JSON.stringify({stakedAssets}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
            method: 'POST',
        })
        .then((response) => response.json())
        .then((response) => {
            setUserUnstakedNFTs(response.data.unstakedNFTs);
            setUserStakedNFTs(response.data.stakedNFTs);
            setAllNFTs(response.data.allNFTs);
        });
    }

    const onQtyChange = (event) => {
        setQuantity(event.target.value);
    }

    const addToCart = () => {
        if(quantity > selectedPerk?.left){
            setErrorText('Perk Out of stock!')
        } else if (quantity < 1) {
            setErrorText('Quantity should 1!')
        } else if (quantity > 1) {
            setErrorText('Quantity should 1!')
        } else {
            let cartItems = JSON.parse(localStorage.getItem('cartItems'));
            let index = cartItems.map(item => item.slug).indexOf(selectedPerk?.slug);
            if (index != -1) {
                handleClose();
                handleOpen2();
            } else {
                cartItems.push({
                    slug: selectedPerk?.slug,
                    desc: selectedPerk?.description,
                    price: selectedPerk?.price,
                    image: selectedPerk?.image,
                    left: selectedPerk?.left,
                    quantity: quantity,
                    type: selectedPerk?.type
                });

                localStorage.setItem('cartItems', JSON.stringify(cartItems));
                setCartItemsCount(cartItems.length);
                handleClose();
                handleOpen1();
            }
        }
    }

    const getRewardGems = async () => {
        fetch(`${api_url}/claim/record`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('gemToken')}`
            },
        })
        .then((response) => response.json())
        .then((response) => response.data.record.gems - response.data.record.usedGems)
        .then((response) => setNewRewardGems(response))
    }

    useEffect(() => {
        try {
            if (userAddress) {
                getUserNFTs(userAddress);
                getRewardGems();
                checkIsApprovedForAll();
            } else {
                setUserUnstakedNFTs([]);
                setUserStakedNFTs([]);
            }
        } catch (e) { }
        localStorage.removeItem('deduction');
        getAllPerks();
        setFlag(1);
        setNewRewardGems(rewardGems)
    }, [userAddress, rewardGems]);

    const handleSignMessage = async (user: any) => {
        let walletAddress = user.walletAddress;
        try {
          const signature = await web3!.eth.personal.sign(
            `I am signing my one-time nonce: ${user.nonce}`,
            walletAddress,
            '' // MetaMask will ignore the password argument here
          );
    
          return { walletAddress, signature };
        } catch (err) {
          throw new Error(
            'You need to sign the message to be able to log in.'
          );
        }
    }
    
    const handleSignup = async (publicAddress: string) => {
    let signature;
    let nonce = Math.floor(Math.random() * 1000000);
    try {
        signature = await web3!.eth.personal.sign(
        `I am signing-up using my one-time nonce: ${nonce}`,
        publicAddress,
        '' // MetaMask will ignore the password argument here
        );
    } catch (err) {
        throw new Error(
        'You need to sign the message to be able to Sign Up.'
        );
    }

    return fetch(`${api_url}/users`, {
        body: JSON.stringify({ publicAddress, signature, nonce }),
        headers: {
        'Content-Type': 'application/json'
        },
        method: 'POST'
    })
        .then(response => response.json())
        .then(response => response.data.result)
    }

    const handleAuthenticate = async (user: any) => {
    let walletAddress = user.walletAddress;
    let signature = user.signature;
    return fetch(`${api_url}/users/auth`, {
        body: JSON.stringify({ walletAddress, signature }),
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then((response) => response.json());
    }

    const handleClick = async () => {
    // Check if MetaMask is installed
    if (!(window as any).ethereum) {
        window.alert('Please install MetaMask first.');
        return;
    }

    if (!web3) {
        try {
        // Request account access if needed
        await (window as any).ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3((window as any).ethereum);
        } catch (error) {
        window.alert('You need to allow MetaMask.');
        return;
        }
    }

    const coinbase = await web3.eth.getCoinbase();
    if (!coinbase) {
        window.alert('Please activate MetaMask first.');
        return;
    }

    const publicAddress = coinbase.toLowerCase();
    console.log('Fetch');

    fetch(`${api_url}/users?walletAddress=${publicAddress}`)
        .then(response => response.json())
        .then(response => response.data.result)
        .then(user => user ? user : handleSignup(publicAddress))
        .then(user => handleSignMessage(user))
        .then(user => handleAuthenticate(user))
        .then(token => {
        localStorage.setItem('gemToken', token.accessToken);
        localStorage.setItem('userAddress', publicAddress);
        localStorage.setItem('cartItems', '[]');
        setUserAddress(publicAddress);
        })
    }

    return (
        <div>
            <div className="stakeVault">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-87 mb-5">
                        <div className="fs-36 fw-700 text-white">My Gem Vault</div>
                        {userAddress &&  <button className="btn legendryBtn-green">
                            <span className="innerText">You Have {totalGems + newRewardGems} Legendary $GEMS</span>
                        </button>}
                        {!userAddress && <button className="btn gradientBtn d-inline-flex">
                             <span className="innerText" onClick={() => handleClick()}>SEE EARNED $GEMS</span>
                        </button>}
                    </div>
                    <div className="stakeCards">
                        <div className="row mx-0">
                            <div className="col-lg-6 px-0 order-2 order-lg-1 pr-lg-4">
                                <div className="stake-heading mb-30">Stake your HoL NFTs and receive Legendary $GEMS</div>
                                {heldUnstakedNFTs}
                                {userAddress && <div className="d-flex justify-content-between align-items-center mb-3">
                                    {heldUnstakedNFTs.length != 0 && <div className="fs-18 fw-700 text-white">You will earn in 90 days for {heldUnstakedNFTs.length} {heldUnstakedNFTs.length <=1?'NFT':'NFTs'}</div>}
                                    {heldUnstakedNFTs.length != 0 && <div className="fs-36 fw-700 text-white">{potentialLegendaryGems} </div>}
                                    {heldUnstakedNFTs.length == 0 && <a href="https://opensea.io/collection/house-of-legends" target="_blank" rel="noopener noreferrer" className="fs-18 fw-700 text-white">Add a new Legend to your collection to earn more $GEMS.</a>}
                                </div>}
                                {!userAddress && <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="fs-18 fw-700 text-white">Please connect your wallet to see your NFTs</div>
                                </div>}
                             <div className="d-flex justify-content-end align-items-center">
                             {!showApproveAll && userUnstakedNFTs.length != 0 &&
                                    <a className="btn stakeAllBtn mr-4" onClick={() => approveAll()}><span className="stakeBtn-inner">Approve All</span> </a>
                                }
                                {userAddress && userUnstakedNFTs.length != 0 &&
                                    <button className="btn stakeAllBtn ml-2" onClick={() => stakeAll()} disabled={!showApproveAll}><span className="stakeBtn-inner">Stake All</span> </button>
                                }
                             </div>
                            </div>
                            <div className="col-lg-6 px-0 order-1 order-lg-2 pl-lg-4 mb-5 mb-lg-0">
                                <div className="stake-heading mb-30">Staking in progress</div>
                                {heldStakedNFTs}
                                {userAddress && <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="fs-18 fw-700 text-white">Legendary $GEMS collected for {heldStakedNFTs.length} {heldStakedNFTs.length <=1?'NFT':'NFTs'}</div>
                                    {heldStakedNFTs.length != 0 && <div className="fs-36 fw-700 text-white">{totalGems + newRewardGems}</div>}
                                    {heldStakedNFTs.length == 0 && <div className="fs-36 fw-700 text-white">0</div>}
                                </div>}
                                {!userAddress && <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="fs-18 fw-700 text-white">Please connect your wallet to see your staked NFTs</div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to='/store'>
                            <a className="btn gradientBtn d-inline-flex">Go to Legendary store</a>
                        </Link>
                    </div>

                    <div className="itemHeading">Items you might like</div>
                    <div className="card-section">
                        {topItems}
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                style={{ opacity: 1 }}
                animation={false}
                onHide={handleClose}
                dialogClassName="customModal"
            >
                <div className="modal-head">
                    <div className="fs25 fw-700 text-white">Store Checkout</div>
                    <div className="pointer" onClick={() => handleClose()}><img src={cross} alt="" /></div>
                </div>
                <div className="modal-body">
                    <div className="modal-input mb-40"><input type="text" min='1' placeholder='Qty.' onChange={onQtyChange} value={quantity} disabled={true}/>
                        <div className="gems">{selectedPerk?.price * quantity} Gems</div>
                    </div>
                    <div className='mb-3'><a className='connectBtn fw-700' onClick={() => addToCart()}>Add To Cart</a></div>
                    <div className="fs-13 fw-400 textWhite70">{errorText}</div>
                </div>
            </Modal>

            <Modal
                show={show1}
                style={{ opacity: 1 }}
                animation={false}
                onHide={handleClose1}
                dialogClassName="customModal"
            >
                <div className="modal-body">
                    <div className="icon">
                        <img src={tick} alt="" />
                    </div>
                    <div className="fs-18 fw-700 text-white text-center my-4">Perk Added To Cart</div>
                    <div><a onClick={() => handleClose1()} className="btn connectBtn d-flex">Okay</a></div>
                </div>
            </Modal>

            <Modal
                show={show2}
                style={{ opacity: 1 }}
                animation={false}
                onHide={handleClose2}
                dialogClassName="customModal"
            >
                <div className="modal-body">
                    <div className="icon">
                        <img src={cross1} alt="" />
                    </div>
                    <div className="fs-18 fw-700 text-white text-center my-4">Item Alraedy In Cart!</div>
                    <div><a onClick={() => handleClose2()} className="btn connectBtn d-flex">Okay</a></div>
                </div>
            </Modal>
        </div>
    )
}

export default Stake