import React from "react";
import './OrderHistory.css';
import dp from '../../assets/Legend11.jpeg';

const OrderHistory = () => {
    return (
        <>
            <div className="orderHistory">
                <div className="container">
                    <div className="fs-36 fw-700 text-white before mb-80">Order History</div>
                    {/* desktop veiw */}
                    <div className="d-none d-lg-block">
                        <div className="order-primary-card">
                            <div className="d-flex mb-30">
                                <div className="col-6">
                                    <div className="d-flex">
                                        <div className="col-6">
                                            <div className="fs-12 fw-500 text-white">Item</div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-12 fw-500 text-white">Value </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-12 fw-500 text-white">Total quantity</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex">
                                        <div className="col-6">
                                            <div className="fs-12 fw-500 text-white">Date/Time</div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-12 fw-500 text-white">Type</div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-12 fw-500 text-white text-center">Status</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* loop on this div */}
                            <div className="border-line">
                                <div className="col-6 px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="col-6 px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="dp-img">
                                                    <img src={dp} alt="" />
                                                </div>
                                                <div className="fs-12 fw-700 text-white px-4 line-clamp">
                                                    Pellentesque dis massa et dis molestie quam rutrum.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-20 fw-700 text-white text-cappitalize">$140.00</div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-16 fw-400 text-white">10</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="col-6 px-0">
                                            <div className="fs1-16 fw-400 text-white">
                                                Apr 12, 2022 at 04:20pm
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-16 fw-400 text-Green">
                                                Delivery
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            <a href="#" className="btn btnGreen"><span className="innerText">Delivered</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-line">
                                <div className="col-6 px-0">
                                    <div className="d-flex align-items-center">
                                        <div className="col-6 px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="dp-img">
                                                    <img src={dp} alt="" />
                                                </div>
                                                <div className="fs-12 fw-700 text-white px-4 line-clamp">
                                                    Pellentesque dis massa et dis molestie quam rutrum.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-20 fw-700 text-white text-cappitalize">$140.00</div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-16 fw-400 text-white">10</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="col-6 px-0">
                                            <div className="fs1-16 fw-400 text-white">
                                                Apr 12, 2022 at 04:20pm
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="fs-16 fw-400 text-Green">
                                                Collection
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            <a href="#" className="btn btnRed"><span className="innerText">Cancelled</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* desktop veiw end */}

                    {/* mobile view */}
                    <div className="d-block d-lg-none">
                        <div className="order-primary-card">
                            <div className="border-line">
                                <div className="d-flex align-items-center justify-content-between mb-30">
                                    <div className="dp-img">
                                        <img src={dp} alt="" />
                                    </div>
                                    <div className="fs-10 fw-400 text-white ml-3 line-clamp">
                                        Pellentesque dis massa et dis molestie quam rutrum.
                                    </div>
                                    <div>
                                        <a href="#" className="btn btnGreen"><span className="innerText">Delivered</span></a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div className="fs-10 fw-400 textWhite70">Value </div>
                                    <div className="fs-12 fw-700 text-white">$140.00 </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div className="fs-10 fw-400 textWhite70">Total quantity</div>
                                    <div className="fs-12 fw-400 textWhite70">10 </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div className="fs-10 fw-400 textWhite70">Date/Time</div>
                                    <div className="fs-12 fw-400 textWhite70">Apr 12,2022 At 04:20pm</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div className="fs-10 fw-400 textWhite70">Type </div>
                                    <div className="fs-14 fw-700 text-Green d-flex align-items-center">
                                        <span className="greenDot mr-1"></span> Delivery</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* mobile view end */}



                </div>
            </div>
        </>
    )
};

export default OrderHistory;