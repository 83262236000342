import { useEffect, useState } from "react";
// import './landing.css';
import { useNavigate } from "react-router-dom";
import headerBg from "../../assets/headerBg.png";
import aboutBg from "../../assets/section-1-bg-trans.png";
import housestakeBg from "../../assets/sectionBg-1.png";
import arrowRight from "../../assets/green-right-arrow.svg";
import sectionBg from "../../assets/sectionbg1.png";
import upcomingBg from "../../assets/upcommingBg.png";
import longArrow from "../../assets/longArrow.svg";
import nftImg from "../../assets/nft-staking-1.png";
import nftImg1 from "../../assets/nft-staking-2.png";
import nftImg2 from "../../assets/nft-staking-3.png";
import nftImg3 from "../../assets/nft-staking-4.png";
import vaultImg from "../../assets/gems-vault-1.png";
import upcoming from "../../assets/upcoming-1.png";
import upcomingNew from "../../assets/upcoming.png";
import upcomingCrowd from "../../assets/upcomming-crowd.png";
import mobileHeader from "../../assets/header-mobile.png";
import Legend1 from "../../assets/Legend1.jpeg";
import Legend2 from "../../assets/Legend2.jpeg";
import Legend3 from "../../assets/Legend3.jpeg";
import Legend4 from "../../assets/Legend4.jpeg";
import Legend5 from "../../assets/Legend5.jpeg";
import Legend6 from "../../assets/Legend6.jpeg";
import Legend7 from "../../assets/Legend7.jpeg";
import Legend8 from "../../assets/Legend8.jpeg";
import Legend9 from "../../assets/Legend9.jpeg";
import Legend10 from "../../assets/Legend10.jpeg";
import Legend11 from "../../assets/Legend11.jpeg";
import Legend12 from "../../assets/Legend12.jpeg";
import Shop1 from "../../assets/Shop1.jpeg";
import Shop2 from "../../assets/Shop2.jpeg";
import Space_Banner from "../../assets/Space_Banner.png";
import gemsImg from "../../assets/gems.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Web3 from "web3";
let web3: Web3 | undefined = undefined;
let api_url = process.env.REACT_APP_BACKEND_URL;

const Landing = ({ setFlag, setUserAddress }) => {
  const [userAddress, setAddress] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    window.location.replace('https://www.houseoflegends.art/')
    setFlag(8);
    setAddress(localStorage.getItem("userAddress"));
    setUserAddress(localStorage.getItem("userAddress"));
  }, []);

  const onStakeClick = () => {
    navigate("/my-vault", { replace: true });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 0.4,
          slidesToScroll: 0.5,
        },
      },
    ],
  };

  const handleSignMessage = async (user: any) => {
    let walletAddress = user.walletAddress;
    try {
      const signature = await web3!.eth.personal.sign(
        `I am signing my one-time nonce: ${user.nonce}`,
        walletAddress,
        "" // MetaMask will ignore the password argument here
      );

      return { walletAddress, signature };
    } catch (err) {
      throw new Error("You need to sign the message to be able to log in.");
    }
  };

  const handleSignup = async (publicAddress: string) => {
    let signature;
    let nonce = Math.floor(Math.random() * 1000000);
    try {
      signature = await web3!.eth.personal.sign(
        `I am signing-up using my one-time nonce: ${nonce}`,
        publicAddress,
        "" // MetaMask will ignore the password argument here
      );
    } catch (err) {
      throw new Error("You need to sign the message to be able to Sign Up.");
    }

    return fetch(`${api_url}/users`, {
      body: JSON.stringify({ publicAddress, signature, nonce }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => response.data.result);
  };

  const handleAuthenticate = async (user: any) => {
    let walletAddress = user.walletAddress;
    let signature = user.signature;
    return fetch(`${api_url}/users/auth`, {
      body: JSON.stringify({ walletAddress, signature }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((response) => response.json());
  };

  const handleClick = async () => {
    // Check if MetaMask is installed
    if (!(window as any).ethereum) {
      window.alert("Please install MetaMask first.");
      return;
    }

    if (!web3) {
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3((window as any).ethereum);
      } catch (error) {
        window.alert("You need to allow MetaMask.");
        return;
      }
    }

    const coinbase = await web3.eth.getCoinbase();
    if (!coinbase) {
      window.alert("Please activate MetaMask first.");
      return;
    }

    const publicAddress = coinbase.toLowerCase();

    fetch(`${api_url}/users?walletAddress=${publicAddress}`)
      .then((response) => response.json())
      .then((response) => response.data.result)
      .then((user) => (user ? user : handleSignup(publicAddress)))
      .then((user) => handleSignMessage(user))
      .then((user) => handleAuthenticate(user))
      .then((token) => {
        localStorage.setItem("gemToken", token.accessToken);
        localStorage.setItem("userAddress", publicAddress);
        localStorage.setItem("cartItems", "[]");
        setAddress(publicAddress);
        setUserAddress(publicAddress);
      });
  };

  const disconnect = () => {
    localStorage.removeItem("userAddress");
    localStorage.removeItem("gemToken");
    localStorage.removeItem("deduction");
    localStorage.removeItem("cartItems");
    setAddress("");
    setUserAddress("");
  };

  return (
    <>
      <div className="landingPage">
        <div className="header" style={{ backgroundImage: headerBg }}>
          <div className="container">
            <div className="row mx-0 align-items-end">
              <div className="col-lg-7">
                <div className="topHeading">
                  <h2 className="bannerText">
                    House of Legends <br className="d-md-block d-lg-none" />
                    <span>Gem Vault</span>
                  </h2>
                  <h3 className="bannerTextDes">
                    Like a Diamond Loyalty Club, But Better
                  </h3>
                  {!userAddress && (
                    <div className="d-flex">
                      <button
                        className="btn connectBtn mb-md-4 mb-lg-0"
                        onClick={() => handleClick()}
                      >
                        Connect your wallet
                      </button>
                      <button className="btn ml-4 connectBtn mb-md-4 mb-lg-0">
                        STAKE YOUR LEGEND
                      </button>
                    </div>
                  )}
                  {userAddress && (
                    <div className="dropdown">
                      <button
                        className="btn connectBtn dropdown-toggle mb-5"
                        type="button"
                        id="dropdownMenuButton1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="connectBtn-innerText">
                          {userAddress.slice(0, 5)}...
                          {userAddress.slice(
                            userAddress.length - 5,
                            userAddress.length
                          )}
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => disconnect()}
                        >
                          Disconnect
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="About-us" style={{ backgroundImage: aboutBg }}>
          <div className="container">
            <div className="row mx-0 align-items-center">
              <div className="col-lg-5 px-0 order-2 order-lg-1 mt-4 mt-lg-0">
                <h2 className="sectionHeading">
                  Collect Your <span>$GEMS</span>
                </h2>
                <p className="sectionDiscription mb-5">
                  Like a Diamond Loyalty Club, But Better. Each Legend produces
                  Gems - tradable tokens within the House of Legends ecosystem,
                  that offer exclusive perks and benefits for yourself and your
                  Legend NFT.
                </p>
                <Link to="/my-vault" className="linkButton">
                  STAKE YOUR LEGEND
                </Link>
              </div>
              <div className="sliderSectionDesign col-lg-7 order-1 order-lg-2 d-none d-lg-block">
                <Slider {...settings}>
                  <div className="mb-70">
                    <img src={gemsImg} className="img-fluid" alt="" />
                  </div>
                  <div className="mb-70">
                    <img src={gemsImg} className="img-fluid" alt="" />
                  </div>
                  <div className="mb-70">
                    <img src={gemsImg} className="img-fluid" alt="" />
                  </div>
                  <div className="mb-70">
                    <img src={gemsImg} className="img-fluid" alt="" />
                  </div>
                </Slider>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="progres">
                    <ProgressBar
                      className="prog-wrapper"
                      barContainerClassName="prog-container"
                      height={"2px"}
                      bgColor={"#55DBCB"}
                      isLabelVisible={false}
                      completed={80}
                    />
                  </div>
                  <div>
                    {/* <a href="#"><img src={longArrow} alt="" /></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="housestakeBg" style={{ backgroundImage: housestakeBg }}>
          <div className="container container1">
            <div className="text-center mb-70">
              <h2 className="sectionHeading">
                Join the Legendary <span>Game</span>
              </h2>
              <p className="sectionDiscription mb-5">
                Don’t miss your opportunity to earn $GEMS today.
              </p>
            </div>
            <Slider {...settings2}>
              <div>
                <div className="stakeNft">
                  <div className="stakeCard">
                    <div className="nftimg">
                      <img src={Legend1} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend2} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend3} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard  mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend4} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stakeNft">
                  <div className="stakeCard">
                    <div className="nftimg">
                      <img src={Legend5} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend6} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend7} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard  mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend8} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="stakeNft">
                  <div className="stakeCard">
                    <div className="nftimg">
                      <img src={Legend9} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend10} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend11} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                  <div className="stakeCard  mt-50 borderBotttom-30">
                    <div className="nftimg">
                      <img src={Legend12} alt="" />
                    </div>
                    <div className="legendaryGameText">
                      Staking is live!
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btnDesign mt-5"
                onClick={() => onStakeClick()}
              >
                STAKE YOUR LEGEND
              </button>
            </div>
          </div>
        </div>

        <div className="gemsVault" style={{ backgroundImage: aboutBg }}>
          <div className="container container1">
            <h2 className="sectionHeading text-center">
              Stake Your Legends <span>Now</span>
            </h2>
            <p className="sectionDiscription mb-5 text-center">
              Staking is live! Stake your Legend NFT to earn $GEMS now.
            </p>
            <div className="row justify-content-md-center mx-0">
              <div className="col-lg-6 col-md-6 mb-4 mb-lg-0">
                <div className="vaultCard">
                  <div className="vault-img">
                    <img src={Shop1} alt="" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between my-3">
                    <div className="fs-26 fw-500 text-white">Gem Vault</div>
                    <div>
                      <Link to="/store" className="fs-18 fw-600 textGreen">
                        Go to Shop
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="vaultCard">
                  <div className="vault-img">
                    <img src={Shop2} alt="" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between my-3">
                    <div className="fs-26 fw-500 text-white">Gem Vault</div>
                    <div>
                      <Link to="/store" className="fs-18 fw-600 textGreen">
                        Go to Shop
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="upcoming-events"
          style={{ backgroundImage: upcomingBg }}
        >
          <div className="container">
            <h2 className="sectionHeading text-center">
              Upcoming <span>Events</span>
            </h2>
            <p className="sectionDiscription mb-5 text-center">
              On Apr 8, 2022 the Legends are joining the Axiom Space mission to
              space with the Israeli Astronaut Eytan Stibbe.
            </p>
            <Slider {...settings}>
              <div>
                <div className="d-flex mb-70">
                  <div className="col-md-12 col-12 px-0">
                    <div className="upcoming-img">
                      <img src={Space_Banner} alt="" />
                      <div className="img-upper-text">
                        The Legends are joining the Axiom Space mission to
                        space.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-3 px-0">
                                        <div className="upcoming-img">
                                            <img src={Space_Banner} alt="" />
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div>
                <div className="d-flex mb-70">
                  <div className="col-md-12 col-12 px-0">
                    <div className="upcoming-img">
                      <img src={Space_Banner} alt="" />
                      <div className="img-upper-text">
                        The Legends are joining the Axiom Space mission to
                        space.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-3 px-0">
                                        <div className="upcoming-img">
                                            <img src={Space_Banner} alt="" />
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div>
                <div className="d-flex mb-70">
                  <div className="col-md-12 col-12 px-0">
                    <div className="upcoming-img">
                      <img src={Space_Banner} alt="" />
                      <div className="img-upper-text">
                        The Legends are joining the Axiom Space mission to
                        space.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-3 px-0">
                                        <div className="upcoming-img">
                                            <img src={Space_Banner} alt="" />
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div>
                <div className="d-flex mb-70">
                  <div className="col-md-12 col-12 px-0">
                    <div className="upcoming-img">
                      <img src={Space_Banner} alt="" />
                      <div className="img-upper-text">
                        The Legends are joining the Axiom Space mission to
                        space.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-3 px-0">
                                        <div className="upcoming-img">
                                            <img src={Space_Banner} alt="" />
                                            <img src={upcomingNew} alt="" />
                                        </div>
                                    </div> */}
                </div>
              </div>
            </Slider>
            <div className="d-flex align-items-center justify-content-between">
              <div className="progres1">
                <ProgressBar
                  className="prog-wrapper"
                  barContainerClassName="prog-container"
                  height={"2px"}
                  bgColor={"#55DBCB"}
                  isLabelVisible={false}
                  completed={80}
                />
                ;
              </div>
              <div>{/* <a href="#"><img src={longArrow} alt="" /></a> */}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
