
import { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import cross from '../../assets/cross.svg';
import dropdownIcon from "../../assets/Arrow (Stroke).svg";
import Select from 'react-dropdown-select';
import tick from '../../assets/tick-green.svg';
import cross1 from '../../assets/cross-circle.svg';
let api_url = process.env.REACT_APP_BACKEND_URL;

const LegendaryStore = ({ setFlag, userAddress, inputGems, setCartItemsCount }) => {
  const [allPerks, setAllPerks] = useState<any>([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedPerk, setSelectedPerk] = useState<any>({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => {setQuantity(1); setErrorText(''); setShow(false)};
  const handleOpen = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleOpen1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleOpen2 = () => setShow2(true);
  const [errorText, setErrorText] = useState('');
  const options = [
    { value: 'Low To High', label: 'Low To High' },
    { value: 'High To Low', label: 'High To Low' },
  ]

  const getAllPerks = () => {
    fetch(`${api_url}/perk`)
    .then(response => response.json())
    .then(response => setAllPerks(response.data.perks.docs))
  }

  useEffect(() => {
    if(inputGems){
      console.log(inputGems);
      fetch(`${api_url}/perk?gems=${inputGems}`)
      .then(response => response.json())
      .then(response => setAllPerks(response.data.perks.docs))
    }else{
      getAllPerks();
    }
    setFlag(2)
  }, [userAddress, inputGems]);

  const onQtyChange = (event) => {
    setQuantity(event.target.value);
  }

  const handleBuyClick = (perk) => {
    if(!userAddress){
      alert('You need to connect your wallet to buy a perk!')
    }else{
      setSelectedPerk(perk);
      handleOpen()
    }
  }

  const addToCart = () => {
    if(quantity > selectedPerk?.left){
      setErrorText('Perk Out of stock!')
    }else if(quantity < 1){
      setErrorText('Quantity should be 1!')
    }else if(quantity > 1){
      setErrorText('Quantity should be 1!')
    }else{
      let cartItems = JSON.parse(localStorage.getItem('cartItems'));
      let index = cartItems.map(item => item.slug).indexOf(selectedPerk?.slug);
      if(index != -1){
        // cartItems[index].quantity = Number(cartItems[index].quantity) + Number(quantity);
        // localStorage.setItem('cartItems', JSON.stringify(cartItems));
        handleClose();
        handleOpen2();
      }else{
        cartItems.push({
          slug: selectedPerk?.slug,
          desc: selectedPerk?.description, 
          price: selectedPerk?.price,
          image: selectedPerk?.image,
          left: selectedPerk?.left,
          quantity: quantity,
          type: selectedPerk?.type
        });
        
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        setCartItemsCount(cartItems.length);
        handleClose();
        handleOpen1();
      }
    }
  }

  const onSortByChange = (event) => {
    console.log(event[0].value)
    if (event[0].value == 'Low To High') {
      fetch(`${api_url}/perk?sort=1`)
      .then(response => response.json())
      .then(response => setAllPerks(response.data.perks.docs))
    }else if(event[0].value == 'High To Low'){
      fetch(`${api_url}/perk?sort=-1`)
      .then(response => response.json())
      .then(response => setAllPerks(response.data.perks.docs))
    }
  }

  let cardsArray = [];
  for (let i = 0; i < allPerks?.length; i++) {
    cardsArray.push(
      <div className="gemCard" key={i}>
        <div className="gem-img">
          <img src={allPerks[i]?.image} alt="" />
        </div>
        <div className="legendBadge">{allPerks[i].description}</div>
        <div className="gemBody">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className="price">$GEMS</div>
              <div className="fs-25 fw-700 text-white">{allPerks[i]?.price} </div>
            </div>
            <div className=""><button className='gradientBtn-outline' onClick={() => { handleBuyClick(allPerks[i]) }}>Buy</button></div>
          </div>
          <div className="footer-card">
            <div>Sold: <span className='fs-25'>{allPerks[i]?.sold}</span></div>
            <div>Available: <span className='fs-25'>{allPerks[i]?.left}</span></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="legendary-section">
        <div className="container">

          <div className="gemText">
          Legendary Store <span className="gradientText">{allPerks?.length} Items</span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-40 before">
            {/* <a className="sortBtn pointer">
              Sort by
              <img src={dropdownIcon} alt="" />
            </a> */}
            <div className="fs-18 fw-700 text-white text-capitalize mb-5">Filter</div>
            <Select className="sortBtn pointr mb-5" placeholder="Sort by Price"
              options={options} values={[]} onChange={onSortByChange} />
          </div>
          <div className="card-section">
            {cardsArray}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose} 
        dialogClassName="customModal"
        >
          <div className="modal-head">
            <div className="fs25 fw-700 text-white">Store Checkout</div>
            <div className="pointer" onClick={() => handleClose()}><img  src={cross} alt="" /></div>
          </div>
          <div className="modal-body">
            <div className="modal-input mb-40"><input type="number" min='1' placeholder='Qty.' value={quantity} disabled={true} onChange={onQtyChange}/> 
            <div className="gems">{selectedPerk?.price * quantity} Gems</div>
            </div>
            <div className='mb-3'><a className='connectBtn fw-700' onClick={() => addToCart()}>Add To Cart</a></div>
            <div className="fs-13 fw-400 textWhite70">{errorText}</div>
          </div>
      </Modal>

      <Modal
        show={show1}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose1}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={tick} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">Perk Added To Cart</div>
          <div><a onClick={() => handleClose1()} className="btn connectBtn d-flex">Okay</a></div>
        </div>
      </Modal>

      <Modal
        show={show2}
        style={{ opacity: 1 }}
        animation={false}
        onHide={handleClose2}
        dialogClassName="customModal"
      >
        <div className="modal-body">
          <div className="icon">
            <img src={cross1} alt="" />
          </div>
          <div className="fs-18 fw-700 text-white text-center my-4">Item Alraedy In Cart!</div>
          <div><a onClick={() => handleClose2()} className="btn connectBtn d-flex">Okay</a></div>
        </div>
      </Modal>


    </div>
  )
}

export default LegendaryStore