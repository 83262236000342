import React, { useEffect } from 'react';
import './Terms.css';

const TermCondions = ({ setFlag }) => {

  useEffect(() => {
    setFlag(11)
  }, [])

  return (
    <>
      <div className="Terms">
        <div className="container">
          <div className="row mx-0 justify-content-center">
            <div className="col-lg-9">
              <div className="fs-70 fw-700 text-white mb-4">Terms & Conditions</div>

              <div className="fs-16 fw-400 text-white mb-3">Effective: 19 December 2021</div>
              <div className="fs-16 fw-400 text-white mb-3">for META BROTHERS LLC (“Company”)</div>
              <div className="fs-16 fw-400 text-white mb-3">
                These Terms and Conditions (hereinafter the “Terms”) govern your use of Company’s website located at <a href="https://www.houseoflegends.art/" className='text-white' target="_blank" rel="noreferrer noopener"><u>https://www.houseoflegends.art/</u></a> and services associated to House of Legends NFT (hereinafter collectively the “Website”).
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Who We Are</div>
              <div className="fs-16 fw-400 text-white mb-3">
                META BROTHERS LLC, the company registered and functioning under the law of the United States of America with headquarters at 12 Fuller Dr West Hartford, CT, 06117 USA, allowing Users to purchase House of Legends NFTs.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                References to “Company”, “we” and “our” throughout these Terms collectively refer to the aforementioned legal entity, and references to “User”, “you” and “your” throughout these Terms collectively refer to any User using the Website.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Website is for House of Legends NFT – non-fungible tokens with creative artworks created by Company and launched on the Blockchain digital network.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Your Consent </div>
              <div className="fs-16 fw-400 text-white mb-3">
                You acknowledge and agree that by accessing or using Website, you are indicating that you have read, understand and agree to these Terms and to be bound by them.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">User</div>
              <div className="fs-16 fw-400 text-white mb-3">
                Website accepts Users only from those countries where purchasing digital collectibles such as NFTs is legal. You are solely responsible for determining whether your accessing and/or use of Website and/or purchasing House of Legends NFTs is compliant with applicable laws in your jurisdiction and you warrant to us that such activity is allowed by laws of the territory where you reside. Any claim against Company brought by you for any reason whatsoever in regard to the above mentioned will be considered void and shall not be accepted.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Legal Age</div>
              <div className="fs-16 fw-400 text-white mb-3">
                You declare that you have reached the age of majority and therefore have the right to make decisions regarding transactions on the Website and purchase of House of Legend NFT. It is your sole responsibility to inquire about the existing laws and regulations regarding the age limitation for the activities available on the Website in the jurisdiction of the territory where you reside.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Ownership on House of Legends NFT</div>
              <div className="fs-16 fw-400 text-white mb-3">
                By purchasing House of Legends NFT, you become an owner of the underlying non-fungible token and holder of the rights on the underlying artwork granted herein. Any change of ownership of House of Legends NFT is mediated entirely by smart contracts without any interference of the Company.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Rights Granted </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Subject to your continued compliance with these Terms, Company grants you a worldwide license to use, copy, and display the underlying artwork of the purchased House of Legends NFT for the following types of uses:
              </div>

              <ul className="my-4">
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    in a marketplace which verifies each owner’s rights to display and remove the respective artwork to ensure that only the actual owner can display and remove the artwork and permits the purchase and sale of House of Legends NFT;
                  </div>
                </li>
                <li><div className="fs-16 fw-400 text-white mb-3">
                  in a third-party website or application which verifies each owner’s rights to display and remove the respective artwork to ensure that only the actual owner can display and remove the artwork and permits the inclusion of House of Legends NFT;</div></li>
                <li><div className="fs-16 fw-400 text-white mb-3">to earn revenue from any of the above; and </div></li>
                <li><div className="fs-16 fw-400 text-white mb-3">to use House of Legends NFT for a personal, non-commercial use. </div></li>
              </ul>
              <div className="fs-16 fw-400 text-white mb-3">Hereinafter “Rights Granted”.</div>
              <div className="fs-16 fw-400 text-white mb-3">Disclaimer</div>
              <div className="fs-16 fw-400 text-white mb-3">
                WEBSITE PROVIDED TO YOU "AS IS" AND WITHOUT ANY WARRANTY WHATSOEVER. WE DISCLAIM ANY AND ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                BY USING WEBSITE AND RELATED SERVICES, YOU ARE ALSO SUBJECT TO COMPANY'S DISCLAIMER LOCATED AT DISCLAIMER, WHICH IS HEREBY INCORPORATED INTO AND MADE PART OF THESE TERMS.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                WE MAKE NO WARRANTY THAT WEBSITE WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                COMPANY SHALL HAVE NO LIABILITY FOR ANY INFORMATION PUBLISHED ON LINKED WEBSITES, CONTAINED IN ANY USER SUBMISSIONS PUBLISHED ON WEBSITE, OR PROVIDED BY THIRD PARTIES. NEITHER COMPANY NOR ITS THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR LOSSES OR FOR LOST REVENUES OR PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE TYPE OF LIABILITY.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                WE MAY SUSPEND OUR WEBSITE FOR INDEFINITE PERIODS OF TIME FOR TECHNICAL MAINTENANCE OR UPGRADE OR OTHERWISE. THE FUNCTIONS AND FEATURES OF WEBSITE ARE SUBJECT TO CHANGE WITHOUT NOTICE.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                COMPANY IS NOT LIABLE FOR ANY DAMAGES OF ANY KIND THAT ARE CAUSED DUE TO USER’S MISUSE OR ABUSE OF WEBSITE, WHICH INCLUDE ALL RESULTING LOSSES BY ANY THIRD PARTIES.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Risks Assumption </div>
              <div className="fs-16 fw-400 text-white mb-3">
                By using Website or services associated with House of Legends NFT, you accept and acknowledge as follows:
              </div>

              <ul className="my-4">
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    inherent security risks of providing information and dealing online over the Internet and nobody may hold Company responsible for any breach of security;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    various risks inherent to using digital currency networks including but not limited to hardware failure, hacking, cybersquatting, software issues, internet connection failure, malicious software, third party interference leading to access to the relevant wallets, smart contracts and other user data; Also, legal risks inherent in trading digital currencies including risk related to regulations and restrictions under different jurisdictions;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    various risks related to regulations regarding NFTs, blockchain technology, cryptocurrencies, and digital networks;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    various risks of any disruptions or other issues impacting cryptocurrencies, tokens, or the digital platforms;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Company will not be responsible or liable to anyone for any loss and take no responsibility for and will not be liable to anyone for any use of the wallet or/and smart contract, including but not limited to any losses, damages, or claims arising from User’s forgotten passwords, incorrectly constructed transactions, or mistyped addresses; corrupted wallet files, smart contracts and any other digital infrastructure; any unauthorized third-party activities, including without limitation the use of viruses, phishing, brute-forcing, or other means of attack; server failure or data loss; any of the unlawful activities of anyone engaged in the project; any enforcement action against anyone engaged in selling and purchasing House of Legends NFT for illegally trading;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Company does not warrant that the wallet, smart contracts and any other digital infrastructure used for the House if Legends NFT are free of viruses or errors, that its content is accurate, that it will be uninterrupted, that it will not be hacked or that defects will be corrected;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    House of Legends NFT is not an investment and Company assumes no risks regarding the price of House of Legends NFT and its fluctuation;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Company will not be responsible or liable to User for any loss of any kind including tokens or other assets, or taken in reliance on material, or information, contained in the wallet used by User or any third party;
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    Nothing on this Website is or is intended to be an offering of securities in any jurisdiction nor does it constitute an offer or an invitation to purchase any of the financial products such as shares, securities, etc.
                  </div>
                </li>
              </ul>

              <div className="fs-16 fw-400 text-white mb-3">Gas Fee and Payments</div>
              <div className="fs-16 fw-400 text-white mb-3">
                If House of Legends NFT is purchased through the Website, any financial transactions that you engage in will be conducted solely through the Ethereum network.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Company does not have the ability to reverse any transactions and it will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage or any other transactions that you conduct via the Ethereum network. Ethereum requires the payment of a transaction fee for every transaction that occurs on the Ethereum network. You will need to pay such transaction fee (gas fee) for each transaction since it is necessary for the network of computers and to run the decentralized Ethereum network. Company will have no insight into or control over any payments or transactions.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Limitation of Liability</div>
              <div className="fs-16 fw-400 text-white mb-3">
                COMPANY ITS SUBSIDIARIES, HOLDING COMPANIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION APPLIES REGARDLESS OF WHETHER SUCH DAMAGES ARE SOUGHT BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL OR EQUITABLE THEORY.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                COMPANY’S TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, ANY PORTION OF THE WEBSITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO USD 100 (ONE HUNDRED UNITED STATES DOLLARS)
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                NOTHING IN THE TERMS EXCLUDES THE LIABILITY FOR COMPANY, ITS SUBSIDIARIES, LICENSORS OR AFFILIATES FOR ANY LIABILITY WHICH CANNOT BE LIMITED BY APPLICABLE LAW. NEITHER PARTY LIMITS OR EXCLUDES LIABILITY FOR FRAUDULENT MISREPRESENTATION.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">User Restrictions and Covenants</div>
              <div className="fs-16 fw-400 text-white mb-3">
                You covenant that you shall not post or cause to post any threatening, libelous, defamatory, obscene material on Website. You shall not do anything or post any information that would:
              </div>

              <ul className="my-4">
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">infringe third party’s intellectual property, privacy or any other right;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">constitute or cause to constitute a criminal offense or/and give rise to civil liability;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">contain a computer virus, worm, Trojan horse, time bomb, spyware or any other destructive element/code;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">contain advertising, promotional materials or third-party trademarks;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">constitute or contain false or misleading statements; or</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">abuse Website and fair rules of games thereof;</div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">violates these Terms or/and any law.</div>
                </li>
              </ul>

              <div className="fs-16 fw-400 text-white mb-3">
                You shall not use any AI or machine learning programs or misuse any algorithm while using Website. Breaching the foregoing entitles Company to restrict your access to Website for an indefinite term.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">To the extent permitted by the applicable law, we:</div>


              <ul className="my-4">
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    do not represent and take responsibility on the accuracy of reliability of information posted to Website by Users/visitors; and
                  </div>
                </li>
                <li>
                  <div className="fs-16 fw-400 text-white mb-3">
                    hereby retain the right to refuse to post or otherwise publish any information, and the right to remove any such material for any ground/reason.
                  </div>
                </li>
              </ul>

              <div className="fs-16 fw-400 text-white mb-3">
                Website is and may be blocked in the future without any notice to you for certain territories due to their regulations. Using VPN to bypass provider’s block is strictly prohibited. Company retains the right to suspend or cancel User’s account any time if User breaches any of the terms hereunder.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Indemnification</div>
              <div className="fs-16 fw-400 text-white mb-3">
                You agree to indemnify and hold harmless Company and its parent, affiliate and subsidiary companies, and each of their respective officers, suppliers, directors, employees, agents, successors, licensees and assigns, from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost, and reasonable attorneys' fees arising out of or in any way related to your breach of these Terms and violation of applicable law.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Privacy</div>
              <div className="fs-16 fw-400 text-white mb-3">
                By using Website and related services, you are subject to Company’s Privacy Policy located at Privacy Policy, which is hereby incorporated into and made part of these Terms.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Intellectual Property Ownership </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Other than the Rights Granted, all rights are expressly reserved by the Company. Nothing herein gives you any rights to any other trademarks or other intellectual property rights belonging to Company and the associated logos.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Website and the content contained on Website, including but not limited to, text, icons, designs, patterns, images, graphics, logos, and videos and the presentation, selection and arrangement thereof, any concepts, frameworks, know-how, software, applications, tools, or other technology, models, processes and algorithms underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof and materials sent to you from Company are protected by intellectual property laws, including but not limited to copyright, trademark and other laws of any jurisdiction and international intellectual property law.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                Company exclusively owns all rights, title and interest in and to Website, including all associated intellectual property rights. You may not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying Website.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                We encourage you to inform us if you identify any intellectual property infringement on the Website.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">User Postings</div>
              <div className="fs-16 fw-400 text-white mb-3">
                You acknowledge and agree that Company owns and have the right to use, publish, and otherwise exploit any and all information that you post or otherwise publish on the Website in postings, forums or message, questionnaire and otherwise, and you acknowledge and agree that, by providing us any such information, you automatically grant to us a worldwide, non-exclusive, assignable, fully paid-up, royalty-free, perpetual, irrevocable license and right to use, reproduce, publish, distribute, modify and otherwise exploit such information for any purpose, and in any form now know or which becomes known after the date of these Terms.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                You hereby waive any claims against Company for any alleged or actual infringements of any intellectual property rights, moral rights, privacy or publicity, or rights of attribution in connection with Company’s use of such information.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Third-party Websites</div>
              <div className="fs-16 fw-400 text-white mb-3">
                We may provide links to third-party websites. Therefore, some of the content on Website may in fact be provided by third parties. Company does not have any responsibility for these third-party websites, which are governed by the terms of conditions and privacy policies, if any, of the applicable third-party content providers.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Applicable Law and Jurisdiction</div>
              <div className="fs-16 fw-400 text-white mb-3">
                These Terms shall be deemed to have been entered into, and shall be interpreted in accordance with the laws of the State of New York, the United State of America without reference to the principles of conflicts of laws. Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or invalidity hereof, shall be referred and finally resolved by the Arbitration in the State of New York, the United States of America. Notwithstanding the foregoing, Company may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction. The place of arbitration will be New York, New York.

                Notwithstanding the provision with respect to applicable substantive law above, any arbitration conducted pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Miscellaneous </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If a part of these Terms becomes invalid or unenforceable under the applicable law, it shall be deemed to have been replaced by a valid and/or applicable provision which most closely matches the intention of Company, and the remainder of the Terms shall remain in force.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                These Terms completely replace any prior agreements between you and Company in relation to Website and any services associated with House of Legends NFT. Each party confirms that, in entering into the Terms it has not relied upon any representations or statements not expressly incorporated herein. Notwithstanding anything else in the Terms.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">
                If Company does not exercise or enforce any legal right or remedy which is contained in these Terms or is available under applicable law, this will not be taken to be a formal waiver of Company’s rights and that those rights or remedies will still be available to Company.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Amendments </div>
              <div className="fs-16 fw-400 text-white mb-3">
                We may periodically change the Terms without prior notice, so please check back from time to time.
              </div>
              <div className="fs-16 fw-400 text-white mb-3">How to Contact Us</div>
              <div className="fs-16 fw-400 text-white mb-3">
                If you have any questions or concerns, or need to submit any information, you can contact us via email, phone or regular mail directed to:
              </div>
              <div className="fs-16 fw-400 text-white mb-3">Address: 12 West Hartford, CT, 06117 USA</div>
              <div className="fs-16 fw-400 text-white mb-3"><a href="#" className='text-white'>Email:<u> legends@meta-brothers.com</u></a></div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermCondions
